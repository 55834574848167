body {
  margin: 0;
  padding: 0;
  background: linear-gradient(0deg, rgb(0, 0, 0), rgb(0, 24, 37), rgb(0, 0, 0));
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: round;

  width: 100%;
  height: 100%;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;  
  overflow: hidden;
}

.modal_categorys_content {
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.infoModal_mobile
.modal_categorys_content
{
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
}
.showInfoModal
.ant-modal-content {
    width: 650px !important;
    min-width: 650px !important;
}
.showPDFRulesModal
.ant-modal-content {
    width: 1050px !important;
    min-width: 750px !important;
}

.infoModal_mobile 
.ant-modal-content {
    width: 320px !important;
    min-width: 320px !important;
}

.infoModal_mobile
.ant-modal-body {
    padding: 10px !important;
}


.modal_categorys_left {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.infoModal_mobile {
    width: 60%;
}

.modal_categorys_left img {
    width: 90%;
    height: auto;
}

.modal_categorys_right {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'PFD';
}

.modal_categorys_title {
    font-size: 23px;
    color: rgb(5, 56, 80);
    font-weight: 800;
}

.infoModal_mobile
.modal_categorys_title
{
    font-size: 14px;
    color: rgb(5, 56, 80);
    font-weight: 800;
}

.modal_categorys_content {
    font-size: 16px;
    font-weight: 600;
}


.infoModal_mobile
.modal_categorys_content {
    font-size: 13px;
    font-weight: 600;
}

.infoModal_mobile
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-right: 0px;
    margin-left: 0px;
}
.trefa_svg_icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.trefa_svg_icon svg {
    width: 100%;
    height: 100%;
}

.trefa_svg_icon img {
    width: 100%;
    height: 100%;
}


.trefa_svg_icon_star_follow .starfill { fill: rgb(34, 117, 185) !important }
.trefa_svg_icon_star_follow .starstroke { stroke: rgb(0, 65, 117) !important }


.trefa_svg_icon_star_participation .starfill { fill: rgb(231, 208, 0) !important }
.trefa_svg_icon_star_participation .starstroke { stroke: rgb(207, 77, 1) !important }

.ui_icon__nofil { fill: none }
.ui_icon__fill { fill: rgb(0, 65, 117) }

.trefa_svg_icon__check
.ui_icon__fill { fill: rgb(255, 255, 255) }

/* RANKS */

.trefa_svg_icon_star_place1 .starfill { fill: rgb(231, 208, 0) !important }
.trefa_svg_icon_star_place1 .starstroke { stroke: rgb(247, 220, 102) !important }
.trefa_svg_icon_star_place1 svg { -webkit-filter: drop-shadow(0px 2px 2px rgb(231, 208, 0)) !important; filter: drop-shadow(0px 2px 2px rgb(231, 208, 0)) !important }


.trefa_svg_icon_star_place2 .starfill { fill: rgb(214, 214, 211) !important }
.trefa_svg_icon_star_place2 .starstroke { stroke: rgb(161, 161, 161) !important }
.trefa_svg_icon_star_place2 svg { -webkit-filter: drop-shadow(0px 2px 2px rgb(255, 255, 255)) !important; filter: drop-shadow(0px 2px 2px rgb(255, 255, 255)) !important }

.trefa_svg_icon_star_place3 .starfill { fill: rgb(214, 153, 72) !important }
.trefa_svg_icon_star_place3 .starstroke { stroke: rgb(160, 90, 33) !important }
.trefa_svg_icon_star_place3 svg { -webkit-filter: drop-shadow(0px 2px 2px rgb(214, 153, 72)) !important; filter: drop-shadow(0px 2px 2px rgb(214, 153, 72)) !important }

.trefa_svg_icon_star_place5 .starfill { fill: rgb(134, 194, 230) !important; }
.trefa_svg_icon_star_place5 .starstroke { stroke: rgb(114, 183, 230) !important }

.shine-div {
  position: relative;
}

.shine-div-canvas {

}
.avatar_container {
    position: relative;
}

.avatar_container_achivment {
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar_container_achivment img {
    width: 100%;
    height: 100%;
    vertical-align: 0%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.avatar_container_raiting {
    background: rgba(255, 255, 255, 0.952);
    border-radius: 4px;
    border: 1px solid gray;
    height: -webkit-fit-content;
    height: fit-content;
    min-width: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    font-family: 'MyriadPro';
    font-weight: bold;
}

.avatar_container_raiting_pro {
    color: rgb(57, 70, 255);
}

.avatar_container_raiting_notpro {
    color: rgb(0, 165, 8);
}

.avatar_container_achivment_shine {
    position: absolute;
}
.event_avatar_main {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.event_avatar_main_extra {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.808);
    border: 1px solid white;
}
.cordao-div {
}

.cordao_svg_front {
    opacity: 0.4;
}

.cordao-div-blank {
    opacity: 0.2;
}

.cordao_svg_back {
    opacity: 0.6;
}

.place_rect {
    height: 100%;
    border-radius: 20%;
    text-align: center;
    font-family: 'Uni';

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.place_rect_as_medal {
    border-radius: 50%;
}

.place_rect_as_medal_blank {
    opacity: 0;
}

.place_rect_place_1 {
    border: 2px solid gold;
    background: linear-gradient(rgb(255, 136, 0), gold);
    color: rgb(255, 255, 255)
}

.place_rect_place_2 {
    border: 2px solid rgb(194, 194, 194);
    background: linear-gradient(rgb(255, 255, 255), rgb(205, 214, 216));
    color: rgb(92, 92, 92)
}

.place_rect_place_3 {
    border: 2px solid #cd7f32;
    background: linear-gradient(rgb(248, 150, 37), #cd7f32);
    color: rgb(255, 255, 255)
}

.place_rect_blank {
    border: 2px solid #9aacb4;
    background: linear-gradient(rgb(94, 120, 124), #4c6166);
    color: rgb(255, 255, 255)  
}
.liketer_like {
    width: 32px;
    height: 32px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border: 2px solid rgb(83, 110, 110);
    border-radius: 7px;
    color: rgb(86, 119, 119);
    font-size: 22px;

    cursor: pointer;
    background: white;

    transition-duration: 0.5s;
}

.liketer_like:hover {
    background: rgb(193, 241, 225);
    border: 2px solid rgb(0, 59, 59);
    color: rgb(0, 59, 59);
    font-size: 25px;
}

.liketer_like_plashka_likes {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    font-family: 'DIN';
    font-size: 22px;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);

    grid-column-gap: 10px;

    -webkit-column-gap: 10px;

            column-gap: 10px;
}


.main_center_container_mobile .liketer_like_plashka_likes {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
}

.liketer_like_plashka_like_container {
    padding: 0px 10px;
    border: 1px solid rgb(84, 175, 211);
    color: rgb(83, 175, 211);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-column-gap: 4px;
    -webkit-column-gap: 4px;
            column-gap: 4px;
    cursor: pointer;
    transition-duration: 0.7s;
}

.liketer_like_plashka_like_container1 {
    border: 1px solid white;
    color: white;
    background: linear-gradient(rgba(255, 255, 255, 0.233), rgba(128, 128, 128, 0.219))
}

.liketer_like_plashka_like_container2 {
    border: 1px solid rgb(255, 187, 0);
    color: rgb(255, 187, 0);
    background: linear-gradient(rgba(248, 253, 182, 0.363), rgba(255, 174, 1, 0.219))
}

.liketer_like_plashka_like_container_liked0 {
    background: rgb(117, 165, 177);
    color: #0B2349;
    border: 1px solid #0B2349;
}
.liketer_like_plashka_like_container_liked1 {
    background: rgb(216, 210, 210);
    color: #0B2349;
    border: 1px solid #0B2349;
}
.liketer_like_plashka_like_container_liked2 {
    background: rgb(245, 166, 20);
    color: #0B2349;
    border: 1px solid #0B2349;
}



.liketer_like_plashka_like_container:hover {
    /* background: rgb(39, 58, 68); */
}

.liketer_like_plashka_like_container_liked0:hover {
    background: rgb(181, 214, 223);
}
.liketer_like_plashka_like_container_liked1:hover {
    background: rgb(255, 255, 255);
}
.liketer_like_plashka_like_container_liked2:hover {
    background: gold;
}

.liketer_modal .ant-modal-body{
    background: linear-gradient(rgb(7, 29, 54), rgb(14, 60, 97)) !important;
    padding: 20px !important;
}
.item_icon_inused {
    
    overflow: hidden;

    background: rgb(232, 236, 238);
    border: 1px solid rgb(206, 199, 199);
    position: relative;

}
.person_little {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(196, 196, 196);
    /* background: rgb(0, 28, 51); */
    /* background: linear-gradient(rgb(0, 29, 46), rgb(1, 43, 68)) */
    background: rgb(255, 255, 255);

    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    margin-left: 0px;

    /* image-rendering: pixelated; */
    /* image-resolution: 3200; */
    image-rendering: optimizeSpeed;

}

.person_little_clickable {
    cursor: pointer;
}

.person_little_clickable:hover {
    background: rgb(183, 255, 219);
}

.person_little__sign {
    border-radius: 50%;
}

.person_little_categorys {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.main_center_container_mobile
.person_little_categorys {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
}

.person_little_results {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    background: none;
}

.main_center_container_mobile
.person_little_results {
    grid-column-gap: 2px;
    -webkit-column-gap: 2px;
            column-gap: 2px;
}

.person_little_category_name {
    height: -webkit-fit-content;
    height: fit-content;
    font-family: 'PFD';
    font-size: 22px;
}

.main_center_container_mobile
.person_little_category_name {
    font-size: 16px;
}

.person_little_results_name {
    height: -webkit-fit-content;
    height: fit-content;
    font-family: 'PFD';
    font-size: 22px;
    min-width: 218px;
    max-width: 218px;
    line-height: normal;
} 

.main_center_container_mobile
.person_little_results_name {
    font-size: 14px;
    min-width: 130px;
    max-width: 130px;
}

.person_little_students_name {
    height: -webkit-fit-content;
    height: fit-content;
    font-family: 'PFD';
    font-size: 22px;
    min-width: 350px; 
    max-width: 350px;

    line-height: 20px;

    overflow: visible;
}

.person_little_results_place {
    min-width: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.main_center_container_mobile 
.person_little_results
.person_little_results_place {
    min-width: 50px;
}

.main_center_container_mobile 
.person_little_results_place {
    min-width: 100px;
}

.person_little_results_rank_mobile {
    min-width: 30px;
    max-width: 30px !important;
    width: 30px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
}

.person_little_students_rank {
    min-width: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.main_center_container_mobile 
.person_little_students_rank {
    min-width: 30px;
}

.person_little_results_race {
    min-width: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: green;
    margin-left: 5px;

    font-size: 12px;
    font-family: 'PFD';
    font-weight: bold;
}
.person_little_results_race img {
    width: 15px;
    height: auto;
}

.main_center_container_mobile 
.person_little_results_race img {
    width: 10px;
    height: auto;
}

.main_center_container_mobile 
.person_little_results_race {
    font-size: 10px;
}

.person_little_results_down {
    color: red;
}

.person_little:last-child {
    border-bottom: none;
}

.person_little_loading {
    background: none;
    opacity: 0.5;
    margin-left: 20px;
}

.person_little__avatar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.person_little__cordao {
    position: absolute;
    top: 50%;
    left: 45%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

}

.person_little__fio {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    font-family: 'MyriadPro';
    margin-bottom: 15px;
    /* color: white; */
}

.person_little__fio_student {
    width: 120px;
    max-width: 120px;
    height: -webkit-fit-content;
    height: fit-content;
    font-family: 'MyriadPro';
    margin-bottom: 15px;
    white-space: nowrap;
    overflow: visible;
}

.person_little__suffix {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: fit-content;
}

.person_little__suffix_gi {
    padding-right: 20px;
    font-family: 'PFD';
    font-size: 19px;
}

.person_little__school {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_little .person_little_students_change {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    margin-left: auto;
    transition-duration: 0.5s;
    transition-property: 'opacity';

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgb(189, 187, 187);
    background: white;
    padding-left: 10px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    width: 0px;
    max-width: 0px;
    left: 100%;
    -webkit-transform: translateX(-110%);
            transform: translateX(-110%);

}

.person_little:hover .person_little_students_change {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
    padding-right: 10px;
    width: -webkit-fit-content;
    width: fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
    min-width: 50px !important;
}


.person_little_category_admin_move {
    opacity: 0;
    visibility: hidden;
    font-family: 'MyriadPro';
    font-size: 14px;
    cursor: pointer;
}

.person_little:hover
.person_little_category_admin_move {
    opacity: 1;
    visibility: visible;
    color: rgb(0, 32, 75);
}

/* /////////////////////// VIDEO //////////////////////// */

.person_little_videoplate {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: none;

    -webkit-animation-name: videoPlateRight;

            animation-name: videoPlateRight;

    transition: none;

    -webkit-animation-duration: 0.5s;

            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 0.7s;
            animation-delay: 0.7s;
    
    -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    
            animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);

    opacity: 0.0;

    

}

.person_little_videoplate_left {
    -webkit-animation-name: videoPlateLeft;
            animation-name: videoPlateLeft;

    -webkit-animation-duration: 0.5s;

            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    opacity: 0.0;
    -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
            animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);

    margin-left: -30%;
}

@-webkit-keyframes videoPlateLeft {
    from {
        margin-left: -30%;
        opacity: 0.0;
    }
    70% {
        margin-left: 10%;
        opacity: 1.0;
    }
    to {
        margin-left: 0%;
        opacity: 1.0;
    }
}

@keyframes videoPlateLeft {
    from {
        margin-left: -30%;
        opacity: 0.0;
    }
    70% {
        margin-left: 10%;
        opacity: 1.0;
    }
    to {
        margin-left: 0%;
        opacity: 1.0;
    }
}
@-webkit-keyframes videoPlateRight {
    from {
        margin-left: 30%;
        opacity: 0.0;
    }
    70% {
        margin-left: -10%;
        opacity: 1.0;
    }
    to {
        margin-left: 0%;
        opacity: 1.0;
    }
}
@keyframes videoPlateRight {
    from {
        margin-left: 30%;
        opacity: 0.0;
    }
    70% {
        margin-left: -10%;
        opacity: 1.0;
    }
    to {
        margin-left: 0%;
        opacity: 1.0;
    }
}



.person_little_videoplate_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;   
    height: 100%;
    width: 100%;
}

.person_little_videoplate_left
.person_little_videoplate_right {
    align-items: flex-end;   
}

.person_little_videoplate_right_name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;   
    height: 30%;
    font-family: 'Uni';
    color: rgb(0, 35, 65);
    padding-left: 10px;
    /* width: 100%; */
    z-index: 1;
    text-align: left;
}

.person_little_videoplate_left 
.person_little_videoplate_right_name {
    align-items: flex-end;   
    padding-left: 0px;
    padding-right: 10px;
    text-align: right;
}

.person_little_videoplate_right_stuff {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;   
    height: 40%;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    width: 100%;
    padding-left: 10px;
    z-index: 1;
}

.person_little_videoplate_left
.person_little_videoplate_right_stuff {
    justify-content: flex-end;
    padding-left: 0px;
    padding-right: 10px;
}



.person_little_videoplate_back {
    position: absolute;
    z-index: 0;
    opacity: 0.2;
    top: -25%;
    left: 60%;

    -webkit-animation-name: videoPlateSchoolRight;

            animation-name: videoPlateSchoolRight;

    -webkit-animation-duration: 0.7s;

            animation-duration: 0.7s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    opacity: 0.0;

}

.person_little_videoplate_left 
.person_little_videoplate_back {
    position: absolute;
    z-index: 0;
    opacity: 0.2;
    top: -25%;
    left: -10%;

    -webkit-animation-name: videoPlateSchoolLeft;

            animation-name: videoPlateSchoolLeft;

    -webkit-animation-duration: 0.7s;

            animation-duration: 0.7s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
    opacity: 0.0;

}

@-webkit-keyframes videoPlateSchoolLeft {
    from {
        margin-left: -30%;
        opacity: 0.0;
    }
    to {
        margin-left: 0%;
        opacity: 0.2;
    }
}

@keyframes videoPlateSchoolLeft {
    from {
        margin-left: -30%;
        opacity: 0.0;
    }
    to {
        margin-left: 0%;
        opacity: 0.2;
    }
}
@-webkit-keyframes videoPlateSchoolRight {
    from {
        margin-left: 30%;
        opacity: 0.0;
    }
    to {
        margin-left: 0%;
        opacity: 0.2;
    }
}
@keyframes videoPlateSchoolRight {
    from {
        margin-left: 30%;
        opacity: 0.0;
    }
    to {
        margin-left: 0%;
        opacity: 0.2;
    }
}

.person_little_video_trefatext span {
    cursor: pointer;
    color: white;
}

.person_little_video_trefatext span:hover {
    color: rgb(1, 112, 163);
}

.person_little__contact {
    font-family: 'MyriadPro';
    margin-left: 9px;
    white-space: nowrap;
}

.person_little__suffix_bets {
    margin-left: auto;
    font-size: 14px;
    font-family: 'PFD';
    line-height: 13px;
    text-align: right;
    min-width: 80px;
}

/* .main_center_container_mobile */
.person_little_category_name_bets {
    font-size: 17px !important;
    line-height: 15px;
}
.loader__container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.laoder__text {
    width: -webkit-fit-content;
    width: fit-content;
    height: 20px;
    text-align: center;
    color: gray;
    font-size: 14px;
    font-family: 'PFD';
}

.loader__img {
    height: 100%;    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.loader__img img{
    height: 100%;
    max-height: 100px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    -webkit-animation-name: 'loader_animation';

            animation-name: 'loader_animation';
    -webkit-animation-duration: 1.2s;
            animation-duration: 1.2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.485, 0.135, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.485, 0.135, 0.15, 0.86);

}

@-webkit-keyframes loader_animation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg); 
    }
}

@keyframes loader_animation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg); 
    }
}
.ui_button_link {

    width: 110px;
    height: 40px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: rgb(0, 27, 53);
    border-radius: 15px;

    opacity: 0.5;
    cursor: pointer;
    transition-duration: 1s;

    z-index: 3;

    grid-column-gap: 10px;

    -webkit-column-gap: 10px;

            column-gap: 10px;

}

.ui_button_link_horizontal {
    width: 65px;
    height: 50px;
    background: rgb(0, 27, 53);
    border-radius: 7px;
    cursor: pointer;
    z-index: 3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 6px;
    row-gap: 6px;
}
.ui_button_link_horizontal span {
    font-family: 'MyriadPro';
    font-size: 12px;
    color: white;
    line-height: 14px;
}
.ui_button_link_horizontal svg {
    width: 23px;
    height: 23px;
}
.ui_button_link_horizontal .fil0 {
    fill: rgb(255, 255, 255) !important;
}



.ui_button_link span {
    font-family: 'MyriadPro';
    font-size: 12px;
    color: white;
    line-height: 14px;
}

.ui_button_link:hover {
    opacity: 0.8;
}

.ui_button_link svg {
    width: 30px;
    height: 30px;
}

.ui_button_link .fil0 {
    fill: rgb(128, 197, 224) !important;
}

.ui_button_link_mini {
    width: 55px;
    height: 20px;
}

.ui_button_link_mini span {
    font-size: 7px;
    line-height: 8px;
}

.ui_button_link_mini svg {
    width: 10px;
    height: 10px;
}

.ui_button_link_mini_long {
    width: 75px;
    height: 24px;
}

.ui_button_link_mini_long span {
    font-size: 12px;
    line-height: 12px;
    padding-top: 1px;
}

.ui_button_link_mini_long svg {
    width: 12px;
    height: 12px;
}

/* LINK BUTTON */

.link_button_light {
    border: 1px solid rgb(58, 116, 96);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgb(204, 226, 223);
    cursor: pointer;
    transition-duration: 0.7s;
}
.link_button_light:hover {
    background: rgb(152, 184, 179);
}

.link_button_light span {
    font-family: 'MyriadPro';
    font-size: 18px;
    color: rgb(34, 73, 60);
}
.video_little_plashka {
    width: 100%;
    height: 100%;
    position: relative;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(243, 243, 243); */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    -webkit-user-select: none;
            user-select: none;
    background: rgb(5, 56, 80);
    /* border-radius: 5px; */
}

/* .video_little_plashka .ui_button_link {
    visibility: hidden;
}

.video_little_plashka .ui_button_link {
    visibility: hidden;
} */

.video_little_plashka-discription {
    font-family: 'MyriadPro';
    font-size: 16px;
    color: white; 

    display: flex;
    flex-direction: column;
}

.video_little_plashka-discription
.person_little_category_name
{
    font-size: 16px;
}

.video_little_plashka-discription
.person_little_category_name a
{
    color: white !important;
    font-weight: 100 !important;
    font-family: 'MyriadPro';
}

.video_little_plashka-discription
.person_little {
    background: none;
}

 
.ytp-title-text {
    visibility: hidden !important;
}
.solo-minisequencerow-div {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  max-width: 800px;
  height: 60px;

  width: -webkit-fit-content;

  width: fit-content;

  border: 1px solid rgb(0, 56, 82);
  transition-duration: 0.2s;

  position: relative;

}

.solo-minisequencerow-div-noscale {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  max-width: 800px;
  height: 60px;

  width: -webkit-fit-content;

  width: fit-content;

  border: 1px solid rgb(0, 56, 82);
  transition-duration: 0.2s;

  position: relative;
}

.solo-minisequencerow-div-isSelected {
  background: rgb(187, 187, 187);
}

.solo-minisequencerow-div:hover {

  transition-duration: 0.5s;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);

}

.solo-minisequencerow-div-person{

  width: 240px;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-right: 10px;

}

.solo-minisequencerow-div-person-noname {
  width: 80px;
}

.solo-minisequencerow-div-person .person_little{
  background: none;
}

.solo-minisequencerow-div-sequence {
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #053850;
  padding: 8px;
  border-radius: 8px;

}

.solo-minisequencerow-div-status {
  width: 70px;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-left: 10px;

  font-family: 'MyriadPro';

}

.solo-minisequencerow-div-status-confirm {

  font-size: 30px !important;
  padding-top: 5px;
  font-family: 'DIN';
  font-size: 30px;
  color:#053850;

}

.solo-minisequencerow-div-status-waiting {

  font-size: 12px;

}


.solo-minisequencerow-div-dashed {
  
  border: 1px solid rgb(36, 38, 39);

}

.solo-minisequencerow-div-status-waiting-dashed {
  color: rgb(131, 130, 130);
}

.solo-minisequencerow-div-video {

  position: absolute;
  top: 0px;
  left: calc(100% - 20px);

}

.solo_icon_checked_tutorial {

  fill: rgb(240, 92, 24);
  stroke-width: 4;
  width: 25px;
  height: 25px;
}

.solo-minisequencerow-div-withvenok {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.solo-minisequencerow-div-withvenok_venok svg {
  width: 19px;
  height: 25px;
}


.solo-minisequencerow-div-withvenok span {
  font-family: 'DIN';
  font-size: 30px;
  color: #ffffff;
  height: 20px;
  line-height: 20px;
  text-transform: uppercase;
}

.solo-minisequencerow-div-withvenok_3 span {
  background: linear-gradient(45deg, #fd7200 33%, #e6bb00 66%, #ffc400);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0B2349;
  display: table;
}

.solo-minisequencerow-div-withvenok_2 span {
  background: linear-gradient(45deg, #374c52 33%, #337575);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0B2349;
  display: table;
}

.solo-minisequencerow-div-withvenok_1 span {
  background: linear-gradient(45deg, #865326 33%, #b98d39 66%, #ac7a0f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0B2349;
  display: table;
}


.solo-minisequencerow-div-withvenok_3 .solo-minisequencerow-div-withvenok_venok svg {
  fill: url(#Gradient1);
}

.solo-minisequencerow-div-withvenok_2 .solo-minisequencerow-div-withvenok_venok svg {
  fill: url(#Gradient2);
}

.solo-minisequencerow-div-withvenok_1 .solo-minisequencerow-div-withvenok_venok svg {
  fill: url(#Gradient3);
}

.solo-minisequencerow-div-withvenok_venok-right {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.solo-minisequencte-div {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: white;

}

.solo-minisequencte-div-card {
  max-width: 40px;
  max-height: 40px;
  margin-left: 4px;
}

.solo-minisequencte-div-card img {
  width: 100%;
  height: auto;
}

.solo-minisequencte-div-seq-name {
  color: black;
  font-size: 15px;
  max-width: 400px;
}

.solo-minisequence-shuffle {
  border: 1px solid rgb(255, 102, 0);
  border-radius: 10px;
}

.mini_sequence_only_tech {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 3px;
  -webkit-column-gap: 3px;
          column-gap: 3px;
  width: -webkit-fit-content;
  width: fit-content;
}

.mini_sequence_only_tech_container {
  height: 90%;
  width: -webkit-fit-content;
  width: fit-content;
  background: #053850;
  border-radius: 7px;
}

.mini_sequence_only_tech_container img {
  height: 100%;
  width: auto;
}
.trefa_text_container {
    /* min-width: 300px; */
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    font-family: 'PFD';
    font-size: 21px;
    font-weight: 300;
    color: rgb(83, 83, 83);
}

.trefa_text_container h1 {
    margin-bottom: 0px;
    font-family: 'DIN';
    color: #053850 !important;
    font-size: 32px !important;
    height: -webkit-fit-content !important;
    height: fit-content !important;
    line-height: 35px !important;
}

.trefa_text_container h2 {
    margin-bottom: 0px;
    font-family: 'DIN';
    color: #053850 !important;
    font-size: 22px !important;
    height: -webkit-fit-content !important;
    height: fit-content !important;
    line-height: 25px !important;
}

.main_center_container_mobile .trefa_text_container {
    font-size: 16px;
}

.trefa_text_container_textblock {
    margin-bottom: 20px;
    word-wrap: break-word;
}

.trefa_text_container_imgblock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.trefa_text_container_imgblock img {
    /* max-width: 70vw !important; */
}

.person_includes_text {
    color: rgb(5, 56, 80);
    min-width: 60px;
}

.person_includes_text:hover {
    color: rgb(1, 112, 163);
}

.include_video {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.technics_includes img {
    background: rgb(0, 29, 61);
    border-radius: 3px;
}
.ain-medal-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.medal-div {
  position: relative;
}

.medal-div-canvas {

}

.medal-div-with-count {
  width: 50%;
  height: 38%;

  border-radius: 15px;

  border: 2px solid rgb(105, 143, 150);
  background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80));

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  top: 70%;
  left: 25%;

  position: absolute;
  color: white;
}

.medal-div-with-count > span {
  font-family: 'PFD';
  font-size: 320%;

}

.medal-div h1 {
  margin-top: 5vw;
  font-size: 4.5vw !important;
  line-height: 4vw !important;
  color: white !important;
  font-family: 'MyriadPro';
}

.medal-div h3 {
  margin-top: 0px;
  font-size: 2.5vw !important;
  line-height: 2vw !important;
  color: rgb(0, 123, 160) !important;
  font-family: 'MyriadPro';
}

/*      СТАТИСТИКА      */
.event_little {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(196, 196, 196);
    /* background: rgb(0, 28, 51); */
    /* background: linear-gradient(rgb(0, 29, 46), rgb(1, 43, 68)) */
    background: linear-gradient(rgb(255, 255, 255), rgb(243, 251, 255))
}

.event_little_for_news {
    background: none;
    border-bottom: none;
    white-space: nowrap;

    overflow: hidden;
}

.event_little:last-child {
    border-bottom: none;
}

.event_little__avatar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.event_little__title {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    font-family: 'MyriadPro';
    /* color: white; */
}

.event_bigvideo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    grid-column-gap: 10px;

    -webkit-column-gap: 10px;

            column-gap: 10px;

    background: rgb(0, 28, 70);
}

.event_bigvideo__title {
    font-family: 'Uni';
    color: white;
}

.main_center_container_mobile .event_bigvideo__title {
    font-size: 10px;
}

.videoplate_main {
    width: -webkit-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    overflow: hidden;
}

.videoplate_main_container {
    position: relative;
}

.videoplate_main_competition {

    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.videoplate_main_competitors {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-column-gap: 5%;
    -webkit-column-gap: 5%;
            column-gap: 5%;
    
    margin-bottom: -50%;

    -webkit-animation-name: vpCompetitors;

            animation-name: vpCompetitors;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes vpCompetitors {
    from {
        margin-bottom: -50%;
    }
    to {
        margin-bottom: 0%;
    }
}

@keyframes vpCompetitors {
    from {
        margin-bottom: -50%;
    }
    to {
        margin-bottom: 0%;
    }
}
.plateAvatar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;

}

.plateAvatar_left {  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.plateAvatar_right {
    width: 100%;
    height: 100%;
    overflow: hidden; 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.plateAvatar_right_text {
    color: black;
    font-size: 90%;
    font-family: 'PFD';
    text-align: left;
    text-transform: uppercase;
}
.item_inventar_components {
    
    border-radius: 15%;
    /* overflow: hidden; */
    background: linear-gradient(rgb(206, 206, 206), white);

    
    background: rgb(141, 153, 160);
    border-radius: 5px;
    position: relative;

}

.item_inventar_components_infomodal {
    background: none;
}

.item_inventar_components img {
    width: 100%;
    height: 100%;
}

.item_inventar_components_type {
    position: absolute;
    width: 35%;
    height: 35%;

    top: 60%;
    left: 5%;    

    border-radius: 50%;
    background: rgba(255, 255, 255, 0.349);
}

.item_inventar_components_last {
    position: absolute;
    width: 35%;
    height: 20%;

    top: 70%;
    left: 55%;    

    text-align: center;
    font-family: 'PFD';

    border-radius: 15%;
    color: white;
    background: rgba(1, 75, 109, 0.493);  
    -webkit-filter: drop-shadow(1px 2px 2px black);  
            filter: drop-shadow(1px 2px 2px black);
}

.item_inventar_components_last_ended {
    background: rgba(236, 0, 0, 0.493);  
}

.item_inventar_components_type img {
    width: 100%;
    height: 100%;
}

.item_inventar_components_used {
    border: 5px solid rgb(0, 46, 65);
}

.item_inventar_components_used_clickable {
    cursor: pointer;
}

.item_inventar_components_event {
    position: absolute;
}

.item_inventar_components_upper {
    position: absolute;
    top: 0px;
    left: 100%;
    width: 0%;
    height: 100%;
    background: rgba(0, 46, 65, 0.753);
    transition-duration: 0.4s;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: 30px;
    color: white;

    grid-column-gap: 20px;

    -webkit-column-gap: 20px;

            column-gap: 20px;
    overflow: hidden;

}

.item_inventar_components:hover 
.item_inventar_components_upper {
    
    position: absolute;
    left: 0px;
    width: 100%;
}

.item_inventar_components_event_shine {
    position: absolute;
}
.persons_little_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 1px solid gray; */

    overflow-y: auto;
    overflow-x: hidden;

}

.persons_little_plashka_addmore {
    width: 100%;
    height: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    background: rgb(0, 57, 83);
    color: white;
    font-family: 'PFD';
    font-size: 16px;

    margin-top: 10px;

}
.global_title {
    height: 60px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    /* font-family: 'Uni'; */
    color: rgb(5, 56, 80);

    
    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: bold;

}

.global_title_text_clicked {
    cursor: pointer;
}

.title_plashka_content {
    /* overflow: hidden; */
}

.trefa_plashka__ {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    /* transform-style: preserve-3d !important;
    perspective: 120px !important;
    perspective-origin: center center; */

    transition-property: top;
    transition-duration: 1s;

}

.trefa_layout__ {
    position: relative;
}

.___no_transition
.trefa_plashka__ {
    transition-duration: 0s;
}
.infoModal
.ant-modal-content {
    width: 850px !important;
    min-width: 850px !important;
}

.infoModalResults
.ant-modal-body {
    background: linear-gradient(rgb(255, 255, 255), rgb(236, 236, 236));
    padding: 0px;
    display: flex;
    justify-content: center;
}

.infoModalMobileStyle
.ant-modal-body {
    padding: 2px;
    width: 300px !important;
    min-width: 300px !important;
}

.modal_medal_content {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    grid-column-gap: 15px;

    -webkit-column-gap: 15px;

            column-gap: 15px;

}

.infoModalMobileStyle .modal_medal_content {

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 100%;

    grid-column-gap: 15px;

    -webkit-column-gap: 15px;

            column-gap: 15px;

}

.modal_medal_left {    
    background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80));
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    width: 300px;
    min-width: 300px;
    min-height: 300px;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
}

.modal_medal_left img {
    width: 100%;
    height: auto;
}

.modal_medal_right {
    width: 100%;
    padding: 10px;
}

.infoModalMobileStyle 
.modal_medal_left {   
    width: 100%;
    min-width: unset;
    min-height: unset;

}

.modal_item_left {    
    background: none;
    width: 300px;
    min-width: 300px;
    min-height: 300px;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
}

.modal_item_right {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 15px;
    font-family: 'PFD';
    color: rgb(0, 57, 83);
    grid-row-gap: 4px;
    row-gap: 4px;
}

.modal_item_right h3 {
    font-size: 30px;
    font-family: 'UNI';
    color: rgb(0, 57, 83) !important;
}

.modal_item_right h4 {
    font-size: 20px;
    font-family: 'UNI';
    color: rgb(0, 57, 83) !important;
    margin-top: 10px;
}

.modal_item_right_row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    grid-column-gap: 20px;

    -webkit-column-gap: 20px;

            column-gap: 20px;
}


.modal_newnews_content {
 width: 100%;
 height: -webkit-fit-content;
 height: fit-content;
 
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: center;

 padding: 15px;

}

.infoModalMobileStyle_news .modal_newnews_content {
    padding-left: 15px;
    padding-right: 15px;
}

.modal_newnews_content_title {
    font-family: 'PFD';
    color: white;
    background: rgb(0, 57, 83);

    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;

    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.infoModalMobileStyle_news .modal_newnews_content_title {

    font-size: 25px;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.modal_newnews_content_downed_type {
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  
    width: 100%;
    margin-left: 10px;
}

.modal_newnews_content_downed {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    grid-column-gap: 10px;

    -webkit-column-gap: 10px;

            column-gap: 10px;
    margin-bottom: 20px;
    width: 100%;
    padding-bottom: 10px;

    border-bottom: 1px solid rgba(128, 128, 128, 0.199);
}

.infoModalMobileStyle_news .modal_newnews_content_downed {
    height: 35px;
}

.modal_newnews_content_downed > span {
    font-size: 15px;
    font-family: 'Uni';
    color: gray;
    margin-left: 10px;
}

.infoModalMobileStyle_news .modal_newnews_content_downed > span {
    font-size: 12px;
}

.modal_newnews_content_downed_type_right {
    margin-left: auto;
}

.modal_newnews_content_text {
    height: -webkit-fit-content;
    height: fit-content;
    width: 100%;
}

.modal_eventSchoolResults_content {  
    
    width: 100%;
    height: 100%;
    
    background: linear-gradient(rgb(255, 255, 255), rgb(209, 208, 208));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    z-index: 2;

    overflow: hidden;
}

.modal_eventSchoolResults_conten_back {

    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0),  rgb(209, 208, 208), rgb(209, 208, 208));
}

.modal_eventSchoolResults_conten_back2 {
    position: absolute;
    z-index: 1;
    width: 78%;
    height: 53%;
    opacity: 0.15;
    top: 17%;
    left: 10.5%;
    
    background-size: cover !important;
    background-position: center !important;
}

.infoModalMobileStyle
.modal_eventSchoolResults_conten_back2 {
    width: 98%;
    height: 53%;
    left: 1%;
}

.infoModalMobileStyle
.medal-div-with-count > span {
    font-family: 'PFD';
    font-size: 180% !important;
}


.modal_eventSchoolResults_conten_upper {

    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: absolute;
    z-index: 3;
}

.modal_eventSchoolResults_content_school {
    height: -webkit-fit-content;
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
}

.modal_eventSchoolResults_content_event_img {
    width: 94%;
    margin-top: 10px;
    border-radius: 20px;
    background-size: cover !important;
    background-position: center !important;
}

.modal_eventSchoolResults_content_event_text {
    font-family: 'PFD';
    color: rgb(5, 56, 80);
    font-size: 22px;
    text-align: center;
}

.infoModalMobileStyle
.modal_eventSchoolResults_content_event_text {
    font-size: 14px;
}

.modal_eventSchoolResults_content_event_text > span {
    font-size: 30px;
}


.infoModalMobileStyle
.modal_eventSchoolResults_content_event_text > span {
    font-size: 14px;
}

.modal_eventSchoolResults_content_event_medals {
    background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80));
    width: 100%;
    margin-top: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-column-gap: 3%;
    -webkit-column-gap: 3%;
            column-gap: 3%;
}

.modal_eventSchoolResults_content_event_medals_count {
    
    
    font-size: 200%;
    font-family: 'PFD';

    width: 100%;
    color: rgb(5, 56, 80);

    margin-top: 2%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  

    grid-column-gap: 3%;  

    -webkit-column-gap: 3%;  

            column-gap: 3%;

    
    margin-bottom: 2%;
}

.infoModalMobileStyle 
.modal_eventSchoolResults_content_event_medals_count {
    font-size: 130%;
}

.modal_eventSchoolResults_content_event_places {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  
    grid-column-gap: 4%;  
    -webkit-column-gap: 4%;  
            column-gap: 4%;
    width: 100%
}

.modal_eventSchoolResults_content_event_places_text {
    font-size: 150%;
    font-family: 'PFD';

    width: -webkit-fit-content;

    width: fit-content;
    color: rgb(5, 56, 80);
}

.modal_eventSchoolResults_content_goldbg {
    border: 2px solid rgb(192, 173, 0);
    background: linear-gradient(rgb(248, 238, 103), rgb(173, 145, 16));
    color: rgb(5, 56, 80);
}

.modal_eventSchoolResults_content_silverbg {
    border: 2px solid rgb(131, 147, 150);
    background: linear-gradient(rgb(236, 236, 236), rgb(136, 136, 136));
    color: rgb(5, 56, 80);
}

.modal_eventSchoolResults_content_bronzebg {
    border: 2px solid rgb(161, 105, 0);
    background: linear-gradient(rgb(255, 175, 55), rgb(141, 97, 15));
    color: rgb(5, 56, 80);
}

.infoModalPDFRules {
    width: max(900px, 70vw) !important;
    height: 800px;
    min-height: 900px;
}

.infoModalPDFRules
.ant-modal-body {
    background: linear-gradient(rgb(255, 255, 255), rgb(236, 236, 236));
    padding: 0px;
    display: flex;
    justify-content: center;
}

.infoModalPDFRules 
.ant-modal-body {
    background: linear-gradient(rgb(255, 255, 255), rgb(236, 236, 236));
    padding: 0px;
    display: flex;
    justify-content: center;
}

.modal_pdfrules_content {
    width: 900px;
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}

.infoModalMobileStyle {
    width: 300px;
    max-width: 300px;
}

.infoModalMobileStyle
.ant-modal-content {
    width: 300px !important;
    min-width: 300px !important;
}


.videoModalResults {
    width: -webkit-fit-content !important;
    width: fit-content !important;
}

.videoModalResults .ant-modal-body {
    padding: 8px;
    background: linear-gradient(rgb(255, 255, 255), rgb(156, 156, 156)) !important;
    border-radius: 20px;
    /* overflow: hidden; */
}

.videoModalResults .ant-modal-content {
    border-radius: 25px;
}

.infoModalNews {
    width: 1100px !important;
}

.infoModalMobileStyle_news {
    width: 100vw !important;
}

.infoModalMobileStyle_news .ant-modal-body {
    padding: 0px;
    background: linear-gradient(rgb(255, 255, 255), rgb(224, 224, 224)) !important;
    /* overflow: hidden; */
}

.ParticipatorsListContent_content {
    width: 100%;
}

.ParticipatorsListContent_content table {
    width: 100%;
}

.ParticipatorsListContent_content td {
    /* width: 50%; */
    /* max-width: 100px; */
    border: 1px solid gray;
}

.modal_list_header {
    font-weight: bold;
}
.events_little_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 1px solid gray; */

    overflow-y: auto;

}
.pumpcin_hw img {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
}

.pumpcin_hw_abs {
    position: absolute;
    z-index: 1200;
}
.menu_search_conatiner {
    width: 130px;
    height: 22px;
    margin-left: auto;
    position: relative;
}

.menu_search_conatiner__input {
    width: 100%;
    height: 100%;
}

.menu_search_conatiner .ant-input {
    height: 20px !important;
}

.menu_search_conatiner__results {
    width: 220px;
    min-height: 50px;
    position: absolute;

    padding: 5px;
    background: white;
    border-radius: 10px;

    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );

            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );

    top: 30px;
    left: -20px;

}

.menu_search_conatiner__results_close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    left: calc(100% - 30px);

    font-size: 15px;
    opacity: 0.5;
    cursor: pointer;

    transition-duration: 0.5s;
    z-index: 3;

}

.menu_search_conatiner__results_close:hover{
    opacity: 1;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.registration-div-all {
  height: -webkit-fit-content;
  height: fit-content;
  font-family: 'MyriadPro';
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.fromModal {
  position: relative !important;
  height: 160px;
  width: 100%;
  left: 0px !important;;
  top: 0px !important;;
}

.registration-div-inside {
  width: 230px;
}

.registration-div-inside form {
  width: 230px;
  font-size: 18px;
}

.registration-div-inside input {
  width: 230px;
  font-size: 18px;
}

.registration_space {
  height: 10px;
}


.registration-div-forgot {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 230px;
  justify-content: space-between;
}

.registration-div-forgot a {
  Color: white;
  margin-right: 15px;
}

.registration-div-forgot Button {
  Color: black;
  width: 100px;
}

.registration-div-forgot Button:hover {
  Color: white !important;
  background: rgb(0, 45, 73)  !important;
}

.registration-div-inside-row {
  display: flex;
  flex-direction: row;
}

.registration-div-inside-row:first-child {
  cursor: pointer;
}

.social-networks-div {

}

@media screen and (min-width:480px) and (max-width:999px) {

   .registration-div-all {
    left: 0px;
    right: initial;
    bottom: 0px;
    top: 0px;
   }

   .registration-div-inside {
     visibility: hidden;
   }

   .registration-div svg {
    visibility: hidden;    
   }

}

.registrationA {
  cursor: pointer;
}


.notification-plashka-content {
    width: 100%;
    padding: 5px;
    height: -webkit-fit-content;
    height: fit-content;

    border-bottom: 1px solid rgb(180, 180, 180);
    background: none;

    transition-duration: 0.2s;

    position: relative;
}

.notification-plashka-content_text {
    max-height: 30px;
    width: 100%;
    overflow: hidden;
}

.notification-plashka-content_texttooltip .person_includes_text {
    color: rgb(187, 241, 239) !important;
}

.notification-plashka-content_texttooltip .person_includes_text {
    color: rgb(187, 241, 239) !important;
}

.notification-plashka-content_texttooltip a {
    color: rgb(187, 241, 239) !important;
}
.notification-plashka-content_texttooltip a:hover {
    color: rgb(145, 192, 190) !important;
}

.notification-plashka-content:hover {
    background: rgb(246, 255, 255);
}

.notification_plashka_content_delete {
    width: 25px;
    height: 25px;

    position: absolute;
    top: calc(5px);
    left: calc(100% - 30px);

    background: rgb(246, 255, 255);
    border-radius: 3px;
    visibility: visible;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

}

/* .ant-tooltip-placement-bottom
.ant-tooltip-inner {
    background: white !important;
} */

/* .notification-plashka-content:hover .notification_plashka_content_delete {
    visibility: visible;
} */
.person_registrtion {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin-left: 10px; */
    /* font-family: 'Uni'; */
    color: rgb(5, 56, 80);

    
    font-family: 'PFD';

}

.person_registrtion_tabs .ant-tabs-tab {
    color: rgb(5, 56, 80);
    text-transform: uppercase;
}

.person_registrtion_tabs .ant-tabs-tab-active {
    color: #1890ff;
}

.person_registrtion_tabs .ant-tabs-left-content {
    color: black;
}

.person_registrtion_all {
    display: flex;
    flex-direction: column;

    font-size: 16px;
    font-family: 'PFD';
    color: rgb(5, 56, 80);
}
.ui_button_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* border: 1px solid gray; */

    cursor: pointer;
    transition-duration: 0.3s;

}

.ui_button_plashka:hover {
    background: rgb(0, 91, 133);
}

.ui_button_plashka_type_dashed {
    background: rgb(105, 151, 165);
}

.ui_button_plashka_type_danger {
    background: rgb(184, 33, 28) !important;
}
.ui_button_plashka_type_danger:hover {
    background: rgb(129, 7, 3) !important;
}

.ui_button_plashka_type_disabled {
    background: rgb(114, 114, 114) !important;
    cursor: not-allowed;
}

.ui_button_plashka_type_disabled:hover {
    background: rgb(114, 114, 114);
    cursor: not-allowed;
}

.ui_button_plashka_text {
    width: -webkit-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;

    font-size: 15px;
    color: white;
    font-family: 'PFD';
}
.money_component {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    background: none;
}

.money_component img {
    width: 60%;
    height: auto;
    background: none !important;
}

.money_component .money_component_amount {
    font-family: 'Uni';
    font-size: 14px;
}
.scores_component {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    background: none;
}

.scores_component img {
    width: 90%;
    height: auto;
    background: none !important;
}

.scores_component_upper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.scores_component .scores_component_amount {
    font-family: 'Uni';
    font-size: 14px;
}
.data_changer_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    padding: 30px;
    padding-top: 10px;
    font-family: 'PFD';

    grid-column-gap: 30px;

    -webkit-column-gap: 30px;

            column-gap: 30px;

}

.data_changer_container_mobile {
    padding: 0px !important;
    padding-top: 5px !important;
}

.data_changer_header {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    color: rgb(90, 90, 90);
    font-size: 18px;
    margin-bottom: 30px;
}

.data_changer_devider {
    width: 100%;
    height: 20px;
}

.data_changer_devider_withtext {
    width: 90%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 35px;
    margin-bottom: 25px;
}

.data_changer_devider_withtext_line_first {
    height: 1px;
    width: 5%;
    min-width: 5%;
    background: rgb(5, 56, 80);
}

.data_changer_devider_withtext_text {
    width: -webkit-fit-content;
    width: fit-content;
    font-family: 'PFD';
    font-size: 14px;
    text-transform: uppercase;
    white-space: nowrap;
    color: rgb(5, 56, 80);
    padding-left: 10px;
    padding-right: 10px;
}

.data_changer_devider_withtext_line_second {
    height: 1px;
    width: 100%;
    background: rgb(5, 56, 80);
}

.data_changer_component {
    width: 250px;
    height: -webkit-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.data_changer_component_title {
    font-size: 17px;
    color: rgb(5, 56, 80);
}

.data_changer_component_title_noneed {
    color: rgb(110, 134, 145);
}

.data_changer_component_comp {
    color: rgb(48, 48, 48);
}

.data_changer_component_text {
    color: rgb(90, 90, 90);
    font-size: 12px;
}

.data_changer_footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}


.data_changer_component_title_participators {
    margin-top: 15px;
}

.data_changer_aps_main {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid rgb(0, 57, 83);
    border-radius: 5px;
}

.data_changer_aps_main::before {
    font-size: 10px;
    content: '\414\43E\431\430\432\438\442\44C   \432\430\440\438\430\43D\442'
}

.data_changer_aps_main_options {
    display: flex;
    flex-direction: row;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.data_changer_aps_left {
    display: flex;
    flex-direction: column; 
    width: 250px;
}

.data_changer_aps_right {
    display: flex;
    flex-direction: column;    
    width: 250px;
    grid-row-gap: 5px;
    row-gap: 5px;
}

.data_changer_aps_left::before {
    content: '\41D\430\437\432\430\43D\438\435';
}

.data_changer_aps_oneselect {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
}

.data_changer_aps_oneselect_adding {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
}

.data_changer_aps_oneselect_adding::before {
    content: '\414\43E\431\430\432\438\442\44C   \432\430\440\438\430\43D\442'
}

.data_changer_aps_main .data_changer_footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.data_changer_payment {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.data_changer_payment_oneblock {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid gray;
    padding-bottom: 15px;
}

.data_changer_payment_oneblock_options {
    display: flex;
    flex-direction: column;
}

.data_changer_payment_oneblock_options_oneoption {
    height: 37px;
}


.data_changer_payment_oneblock_left {
    display: flex;
    flex-direction: row;
    min-width: 300px;
    padding-top: 43px;
}

.data_changer_payment_oneblock_title {
    min-width: 150px;
    display: flex;
    flex-direction: column;
    min-height: 50px;
    grid-row-gap: 3px;
    row-gap: 3px;
}

.data_changer_payment_oneblock_title>span {
    font-size: 20px;
}

.data_changer_payment_oneblock_checksplit {
    display: flex;
    flex-direction: row;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;  
    -webkit-transform: scale(0.9);  
            transform: scale(0.9);
}

.data_changer_payment_oneblock_right {
    display: flex;
    flex-direction: row;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.data_changer_payment_onepayment_block {
    display: flex;
    flex-direction: column;
}

.data_changer_payment_onepayment_date {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
    height: 40px;
    width: 150px;
}

.data_changer_payment_onepayment_inputs {
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
    row-gap: 5px;
}

.data_changer_payment_oneblock_blank {
    border: 1px solid gray;
    border-radius: 15px;
    color: gray;
    cursor: pointer;
    padding: 20px;
    text-align: center;
    background: rgb(247, 247, 247);

    transition-duration: 1s;

    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.data_changer_payment_oneblock_blank:hover {
    
    border: 1px solid rgb(71, 71, 71);
    background: rgb(255, 255, 255);
    color: rgb(71, 71, 71);
}

.data_changer_payment_oneblock_blank>span {
    width: -webkit-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
}



/* СКИДКИ !!!!!!!!!! */

.data_changer_payment_sales_title {

    margin-top: 20px;

    font-family: 'PFD';
    text-transform: uppercase;
    font-size: 17px;
    color: rgb(5, 56, 80);
}

.data_changer_payment_sales {
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
    row-gap: 5px;
}

.data_changer_payment_sales__blank {
    border: 1px solid gray;
    border-radius: 5px;
    color: gray;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    background: rgb(247, 247, 247);

    transition-duration: 1s;

    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.data_changer_payment_sales__blank:hover {
    
    border: 1px solid rgb(71, 71, 71);
    background: rgb(255, 255, 255);
    color: rgb(71, 71, 71);
}

.data_changer_payment_sales__blank>span {
    width: -webkit-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
}

.data_changer_payment_sales__one {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
}


.data_changer_payment_sales__one_right {
    width: 150px;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    grid-column-gap: 5px;

    -webkit-column-gap: 5px;

            column-gap: 5px;
}

.data_changer_payment_sales__one_right > span {
    min-width: 90px;
    text-align: right;
}


/* ADMISSION */

.data_change_admission_add_typical_title {
    font-family: 'PFD';
    text-transform: uppercase;
    font-size: 14px;
    color: rgb(5, 56, 80);
    margin-top: 20px;
}

.data_change_admission_add_typical_components {
    display: flex;
    flex-direction: row;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.data_change_admission_adding_component {
    width: -webkit-fit-content;
    width: fit-content;
    height: 30px;
    padding: 10px;
    padding-top: 6px;

    background: rgb(159, 175, 175);
    font-size: 12px;
    color: rgb(2, 30, 43);

    border-radius: 5px;
    cursor: pointer !important;
}


.dataChanger_scoresComponent {
    display: flex;
    flex-direction: row;
    width: 100%;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.dataChanger_scoresComponent_one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.social_icon_main {
    margin: 0px;
    padding: 0px;

    border-radius: 15%;

    background: rgb(0, 57, 83);
    display: flex;
}

.social_icon_main:hover {
    background: rgb(0, 91, 133);
}

.social_icon_main img {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    height: 100% !important;
}
.operation_little {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(196, 196, 196);
    /* background: rgb(0, 28, 51); */
    /* background: linear-gradient(rgb(0, 29, 46), rgb(1, 43, 68)) */
    background: rgb(255, 255, 255);

    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    margin-left: 0px;

    /* image-rendering: pixelated; */
    /* image-resolution: 3200; */
    image-rendering: optimizeSpeed;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;

}

.operation_little_title {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    grid-row-gap: 5px;
    row-gap: 5px;
}

.operation_little_title_date {
    height: 10px;
    font-family: 'PFD';
    font-size: 11px;
    color: rgb(5, 56, 80);
}

.operation_little_title_text {
    font-family: 'PFD';
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
}

.operation_little_subject {
    width: -webkit-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.operation_little_resourses {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* padding-left: 30px; */

    grid-column-gap: 5px;

    -webkit-column-gap: 5px;

            column-gap: 5px;
}

.operation_little_resourses img {
    height: 41px;
    width: auto;
}

.operation_little_one_resourse {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.operation_little_one_resourse_img {
    height: 25px;
}

.operation_little_one_resourse_img img {
    height: 100%;
    width: auto;
}

.operation_little_one_resourse_value {
    text-align: center;
    width: 100%;
    font-family: 'PFD';
    font-size: 15px;
    font-weight: bold;
    color: #006b09;
}

.operation_little_one_resourse_value_minus {
    color: #5c0000;
}
.modal_notification_body {
    width: 100%;
    height: 100%;
}

.modal_notification_button {
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}


.loginShowPerson {
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;

}

.loginShowPerson_personFIO {
    color: white;
    font-family: 'MyriadPro';
    margin-right: 10px;
    cursor: pointer;
}

.loginShowPerson_personFIO a {
    color: white !important;
    margin-right: 10px;
}

.main_center_container_mobile .loginShowPerson_personFIO {
    color: rgb(0, 72, 105);
    font-family: 'MyriadPro';
}

.main_center_container_mobile .loginShowPerson_personFIO a {
    color: rgb(0, 72, 105) !important;
    margin-right: 10px;
}

.loginShowPerson_MiniMenu_back {
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgba(0, 72, 105, 0.178);
    width: 100vw;
    height: 100vh;
    z-index: 999;
}

@-webkit-keyframes minimenublah {
    from { opacity: 0; top: 30px}
    to {opacity: 1; top: 40px}
}

@keyframes minimenublah {
    from { opacity: 0; top: 30px}
    to {opacity: 1; top: 40px}
}

.loginShowPerson_MiniMenu {
    width: 300px;
    height: -webkit-fit-content;
    height: fit-content;

    position: absolute;
    background: rgb(255, 255, 255);
    /* background: linear-gradient(#d1d1d1, white); */
    border: 1px solid rgb(199, 199, 199);
    left: -180px;
    top: 40px;
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    border-radius: 5px;

    -webkit-animation-name: 'minimenublah';

            animation-name: 'minimenublah';
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;

    z-index: 1000;
}

.loginShowPerson_MiniMenu_item {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;
    font-family: 'PFD';

}

.loginShowPerson_span {
    cursor: pointer;
}

.loginShowPerson_MiniMenu_item > span {
    color: black;
    cursor: pointer;
}

.loginShowPerson_MiniMenu_item > span:hover {
    color: rgb(0, 72, 105);
}

.loginNoUser {
    margin-left: auto;
    margin-right: 20px;
}

.loginEnter {
    font-family: 'MyriadPro';
    color: white;
    cursor: pointer;
}

.main_center_container_mobile .loginEnter {
    color: rgb(0, 72, 105);
}

.login-modal .ant-modal-body {
    background: linear-gradient(rgb(209, 209, 209), white)
}

.resourses-modal .ant-modal-body {
    padding: 5px !important;
}

.registration-modal .ant-modal-body {
    background: linear-gradient(rgb(245, 245, 245), white);
    /* width: 1200px; */
}

.registration-modal-mobile .ant-modal-body {
    background: linear-gradient(rgb(245, 245, 245), white);
    /* width: 95%; */
}

.forget_login_container {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.forget_login_container_social {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 20px;
}
.driver_button_menu {
    position: fixed;
    top: 5px;
    left: 5px;
    z-index: 200;
}

.driver_button_menu_driver_title {
    font-family: 'PFD';
    font-size: 18px;
    color: rgb(5, 56, 80);
}

.menu-main {
  width: 100%;
  height: 56px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  position: relative; 
}

.main_center_container_mobile .menu-main {
    background: linear-gradient(white, rgb(226, 226, 226));
    border-bottom: 1px solid rgb(212, 212, 212);
}

.menu-main-left-thing {
    width: 20px;
    min-width: 20px;
    height: 32px;
}

.menu-logo {
    width: 170px;
    min-width: 170px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu_mini_logo {
    margin-left: 50px;
}

.menu-logo img {
    width: 131px;
    height: auto;
}

.main_center_container_mobile .menu-main {
    height: 46px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.main_center_container_mobile .menu-logo,
.main_center_container_mobile .menu_mini_logo
{
    width: 36px;
    min-width: 35px;
}

.main_center_container_mobile .menu-logo img,
.main_center_container_mobile .menu_mini_logo img 
{
    width: 36px;
    height: auto;
}

.menu-right-part {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;

    position: relative;

}

.menu-right-part-back {
    position: absolute;
    left: 0px;
    top: 12px;

    width: 100%;
    height: 32px;

    z-index: 0;
}

.menu-right-part-upper {
    position: absolute;
    left: 0px;
    top: 0px;

    width: 100%;
    height: 56px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    z-index: 1;
}

.menu-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 40px;
    min-width: 600px;
}

.menulink {
    color: white;
    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 12px;
    font-size: 15px;
    font-weight: 300;
}

.menulink a {
    color: white !important;
}

.menu-links_mini {

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.menu-links_mini .menulink {
    width: 100%;
    height: 42px;
    margin-top: 10px;
    border-bottom: 1px solid rgb(196, 194, 194);
}

.menu-links_mini .menulink a {
    color: rgb(5, 56, 80) !important;
}

.driver_menu_content {

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 10px;
}

.driver_menu_content .menu_search_conatiner {
    margin-left: 0px;
    margin-bottom: 15px;
}
.__status_default {
    padding-left: 5px;
    padding-right: 5px;

    height: 22px;
    background: rgb(148, 90, 4);
    border: 1px solid rgb(148, 90, 4);
    border-radius: 5px;
    color: white;
    position: relative;

    width: 90px;

    text-align: center;
}

.main_center_container_mobile .__status_default {

    -webkit-transform: scale(0.7);

            transform: scale(0.7);

}

.event_big_plashka__status_text {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    font-family: 'MyriadPro';
}


.event_big_plashka__status_not_published {
    background: rgb(179, 7, 7) !important;
    border: 1px solid rgb(212, 3, 3) !important;

    width: 120px !important;
    left: calc(100% - 140px) !important;
    cursor: pointer !important;
}

.event_big_plashka__status_registration_not_open {
    background: rgb(66, 15, 82) !important;
    border: 1px solid rgb(39, 5, 49) !important;

    width: 150px !important;
    left: calc(100% - 170px) !important;
}

.event_big_plashka__status_registration_open {
    background: rgb(0, 126, 6) !important;
    border: 1px solid rgb(0, 151, 20) !important;

    width: 150px !important;
    left: calc(100% - 170px) !important;
}

.event_big_plashka__status_registration_closed {
    background: rgb(185, 59, 0) !important;
    border: 1px solid rgb(212, 99, 46) !important;

    width: 150px !important;
    left: calc(100% - 170px) !important;
}

.event_big_plashka__status_past {
    background: rgb(0, 70, 117) !important;
    border: 1px solid rgb(0, 69, 148) !important;

    width: 90px !important;
    left: calc(100% - 110px) !important;
}

.event_big_plashka__status_online {

    width: 90px !important;
    left: calc(100% - 110px) !important;  

    -webkit-animation-name: 'goinggo';  

            animation-name: 'goinggo';
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

@-webkit-keyframes goinggo {
    from {
        background: rgb(129, 0, 0);
        border: 1px solid rgb(255, 45, 45);
    }
    to {
        background: rgb(255, 0, 0);
        border: 1px solid rgb(250, 179, 179);
    }
}

@keyframes goinggo {
    from {
        background: rgb(129, 0, 0);
        border: 1px solid rgb(255, 45, 45);
    }
    to {
        background: rgb(255, 0, 0);
        border: 1px solid rgb(250, 179, 179);
    }
}


.event_big_plashka__status_cliced .global-tooltip {
    cursor: pointer !important;
}

.event_big_plashka .global-tooltip::after {
    margin-left: -300px !important;
    /* margin-right: 100px !important; */
    margin-top: 20px;
}

.event_big_plashka__status_past .global-tooltip::after {
    margin-left: -100px !important;
    /* margin-right: 100px !important; */
    margin-top: 20px;
}

.__status_default {
    left: 0px !important;
}
.event_usual_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */
    background-size: cover !important;
    background-position: center !important;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    position: absolute;
}

.event_usual_plashka_fon {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left:0px;
    /* z-index: 1; */
    background-size: cover !important;
    background-position: center !important;
}

.event_usual_plashka_fon_usual {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left:0px;
    /* z-index: 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0, 68, 100);
    font-family: 'PFD';
    font-size: 20px;
    color: white;
}

.event_usual_plashka_fon_usual > span {
    width: 70%;
    line-height: 18px;
}

.event_usual_plashka__date {
    width: 60px;
    height: 30px;
    background: rgba(5, 56, 80, 0.781);
    border: 1px solid rgb(5, 56, 80);
    border-radius: 5px;
    color: white;
    position: absolute;
    top: 10px;
    left: 10px;
    /* z-index: 2; */
}

.event_usual_plashka__date_text {
    width: -webkit-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    font-family: 'MyriadPro';
    line-height: 15px;
    /* z-index: 1; */
}

.event_usual_plashka__status {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    border-radius: 5px;
    color: white;
    position: absolute;
    top: 10px;
    left: 0px;   
    display: flex;
    flex-direction: row;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
    justify-content: flex-end;
    padding-right: 10px;
    /* z-index: 1; */
}

.event_usual_plashka__count {

    position: absolute;
    top: 45px;
    left: 10px;   

    height: 23px;
    width: -webkit-fit-content;
    width: fit-content;
    
    background: rgba(5, 56, 80, 0.884);
    border: 1px solid rgb(5, 56, 80);
    border-radius: 5px;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding-left: 5px;
    padding-right: 5px;

    grid-column-gap: 3px;

    -webkit-column-gap: 3px;

            column-gap: 3px;

    color: white;
    /* z-index: 1; */
}

.main_center_container_mobile
.event_usual_plashka__status {
    padding-right: 0px;
    top: 5px;
}

.event_usual_plashka__downed_title {
    position: absolute;
    width: 100%;
    height: max(20%, 35px);
    left: 0px;
    top: calc(100%);
    background: rgba(5, 56, 80, 0.925);

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    transition-duration: 1s;
    opacity: 0;
    line-height: 15px;
    /* z-index: 1; */

}


.event_usual_plashka:hover
.event_usual_plashka__downed_title {
    -webkit-animation: event_title_animation;
            animation: event_title_animation;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; 
    transition-duration: 1s;
    opacity: 1;
}

.event_usual_plashka__downed_title_text {
    color: white;
    font-family: 'MyriadPro';
    margin-left: 20px;
    height: -webkit-fit-content;
    height: fit-content;
}


.main_center_container_mobile 
.event_usual_plashka__downed_title_text {
    margin-left: 14px;
    font-size: 14px !important;

}

@-webkit-keyframes event_title_animation {
    from {
        top: calc(110%);
    }
    to {
        top: calc(100% - max(20%, 35px));
    }
}

@keyframes event_title_animation {
    from {
        top: calc(110%);
    }
    to {
        top: calc(100% - max(20%, 35px));
    }
}

@-webkit-keyframes event_title_animation_ {
    from {
        top: calc(110%);
    }
    to {
        top: calc(100% - max(20%, 35px));
    }
}

@keyframes event_title_animation_ {
    from {
        top: calc(110%);
    }
    to {
        top: calc(100% - max(20%, 35px));
    }
}
.calendar_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(243, 243, 243); */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */

    -webkit-user-select: none;

            user-select: none;
    /* border-radius: 5px; */
    /* border: 1px solid rgb(197, 197, 197); */
}

.calendar_header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
}

.calendar_header_item {
    width: 30%;
    text-align: center;
    font-family: 'MyriadPro';
    font-size: 16px;
    cursor: pointer;
    color: rgb(37, 37, 37);
    transition-duration: 0.5s;
}

.calendar_header_item:hover {
    font-size: 17px;
}

.calendar_header_item___selected {
    font-weight: bold;
    font-size: 17px;
    color: rgb(5, 56, 80);
}


.calendar_plashka_outerheader {
    width: 100%;
    height: 40px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: 'PFD';
    font-size: 26px;
    color: rgb(5, 56, 80);

    grid-column-gap: 5px;

    -webkit-column-gap: 5px;

            column-gap: 5px;
}





.calendar_days_header {    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
}

.calendar_days_header_item {
    width: 13%;
    min-width: 13%;
    text-align: center;
    font-family: 'MyriadPro';
    font-size: 16px;
    color: rgb(0, 56, 94); 
}

.calendar_body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;

    grid-row-gap: 2px;

    row-gap: 2px;
    grid-column-gap: 2px;
    -webkit-column-gap: 2px;
            column-gap: 2px
}

/* Это у нас собственно плашечка */
.calendar_body_oneday {
    width: 13%;
    min-width: 13%;
    text-align: center;
    /* border: 1px solid rgb(212, 212, 212); */
    height: 27px;
    font-family: 'Uni';

    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.calendar_body_oneday > span {
    padding-right: 7px;
    padding-bottom: 3px;
    font-size: 12px;
}

.calendar_mini_events {
    position: absolute;
    width: -webkit-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    /* width: 80px; */

    /* background: rgb(223, 223, 223); */
    /* border-radius: 3px; */

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    top: -12px;
    left: -7px;

    overflow: visible;
}

.calendar_body_oneday__blank {
    /* border: 1px solid rgb(233, 233, 233); */
    border: none;
}

.calendar_body_oneday__today {
    color: rgb(5, 56, 80);
    border: 2px solid #EF7F1A;
    /* background: #EF7F1A; */
    border-radius: 10px;

    
    border: 2px solid #003038;
}

.calendar_body_oneday__past {
    color: rgb(187, 187, 187);
}

.calendar_body_oneday__event {
    background: rgb(185, 227, 255); 
    /* color: white; */
    border-radius: 10px;
    color: rgb(5, 56, 80);
}
.calendar_body_oneday__event_championship {
    /* background: #dffcb8;  */
}

/* .calendar_body_oneday__event span {
    background: rgb(0, 56, 94); 
    color: white;
    border-radius: 30px;
} */

.ant-popover-inner-content {
    padding: 0px;
    margin: 0px;
    width: -webkit-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
}

.calendar-popover-content {
    min-width: 250px;
}
.numberText {
    width: 40px;
    height: 60px;

    display: flex;
    flex-direction: column;
}

.numberText_upper {
    width: 100%;
    height: 70%;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.numberText_lower {
    width: 100%;
    height: 30%; 
    max-height: 30%;  
    overflow: hidden; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.numberText_upper_text {
    color: rgb(0, 17, 36);
    font-size: 90%;
    font-family: 'Uni';
    text-align: center;
}

.numberText_lower_text {
    color: rgb(53, 53, 53);
    font-size: 90%;
    font-family: 'Uni';
    text-align: center;
}
.statistics_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(243, 243, 243); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    -webkit-user-select: none;
            user-select: none;
    /* border-radius: 5px; */
    padding-left: 5px;
    padding-right: 5px;
}

.statistics_plashka_next {
    /* width: 65px; */
    font-size: 9px;
    line-height: 10px;
    margin-left: auto;
}
.onenew_little {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background: rgb(0, 28, 51); */
    /* background: linear-gradient(rgb(0, 29, 46), rgb(1, 43, 68)) */
    padding-left: 10px;
    padding-right: 10px;

}

.onenew_little__date {
    width: 100%;
    height: 18px;
    font-size: 11px;
    color: #EF7F1A;
    font-family: 'Uni';

    display: flex;
    flex-direction: row;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
    overflow: hidden;
}

.onenew_little__date > span {
    min-width: -webkit-fit-content;
    min-width: fit-content;
}

.onenew_little__title {
    width: 100%;
    max-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    font-size: 18px;
    line-height: 15px;
    font-family: 'MyriadPro';
}

.onenew_little__devider {
    width: 90%;
    height: 1px;
    background: black;
    margin-top: auto;

}

.onenew_little__title_event_newnews {
    cursor: pointer;
    transition-duration: 1s;
}

.onenew_little__title_event_newnews:hover {
    color: rgb(1, 112, 163)
}

.onenew_little__title_hot {
    color: #cf680d;
    font-weight: bold;
}
.news_little_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 1px solid gray; */

    overflow-y: auto;

}
.slider_some_plashka {
    width: 100%;
    height: 100%;
    background: none;

    /* background-position: center; */

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    overflow: hidden;

}

.slider_some_plashka_one {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.slider_some_plashka_one_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slider_some_plashka_one_top
img {
    width: 95%;
    height: auto;
}

.main_center_container_mobile 
.slider_some_plashka_one_top
img {
    width: 70%;
    height: auto;
}

.slider_some_plashka_one_bottom {
    height: 100%;
    padding: 5%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    font-size: 15px;
    font-family: 'MyriadPro';
    text-align: center;
    line-height: 14px;
}

.main_center_container_mobile 
.slider_some_plashka_right_text {
    font-family: 'MyriadPro';
    line-height: 14px;
    font-size: 16px;
}

.kmo_quest_plashka {
    width: 100%;
    height: 100%;
    background: linear-gradient(rgb(235, 235, 235), white);

    /* background-position: center; */

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid rgb(226, 223, 223);

    overflow: hidden;

}

.kmo_quest_plashka_group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    font-family: "PFD" !important;
}


.main_center_container_mobile
.kmo_quest_plashka_groupname {
    font-size: 9px;
    text-align: center;
}

.kmo_quest_plashka_amount img {
    width: 20px;
    height: 20px;
}
.owl_quest_plashka {
    width: 100%;
    height: 100%;
    background: none;

    /* background-position: center; */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    overflow: hidden;

}

.owl_quest_plashka img {
    width: 100%;
    height: auto;
}

.seqmonitor_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(243, 243, 243); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    -webkit-user-select: none;
            user-select: none;
    /* border-radius: 5px; */
}

.seqmonitor_plashka_vertical {
    flex-direction: column-reverse;
}

.seqmonitor_plashka_sequences {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    overflow-y: auto;
    overflow-x: hidden;
}

.seqmonitor_plashka_monitor {
    background: rgb(0, 36, 59);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.inlarge_event_row {
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    border-top: 1px solid rgb(214, 214, 214);

}

.inlarge_event_row_logo {
    width: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.main_center_container_mobile .inlarge_event_row_logo {
    width: 90px;
    min-width: 60px;
}

.inlarge_event_row_title {
    font-family: 'PFD';
    font-size: 18px;
    color: rgb(5, 56, 80);
    max-width: 420px;
    width: 420px;
    min-width: 420px;
}

.main_center_container_mobile .inlarge_event_row_title {
    font-size: 15px;
    width: 210px;
    min-width: 210px;

    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.inlarge_event_row_title__down {
    display: flex;
    flex-direction: row;
    height: 16px;
}

.inlarge_event_row_dates {
    font-family: 'PFD';
    font-size: 16px;
    color: rgb(5, 56, 80);
    width: 100px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.main_center_container_mobile .inlarge_event_row_dates {
    font-size: 13px;
    width: unset;
}


.inlarge_event_row_status {
    font-family: 'PFD';
    color: rgb(5, 56, 80);
    width: 130px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.inlarge_event_row_region {
    font-family: 'PFD';
    color: rgb(5, 56, 80);
    width: 130px;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.calendar_page {
    background: white;
    width: 100%;
    min-height: 300px;

    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 70px;

}

.calendar_page
.data_changer_header {
    margin-bottom: 5px;
}

.calendar_page_seasons {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}


.main_center_container_mobile 
.calendar_page_seasons {
    justify-content: center;
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
            column-gap: 8px;
}

.calendar_page_seasons_season {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.calendar_page_seasons_season > span {
    font-family: 'PFD';
    font-size: 20px;
    color: gray;
    cursor: pointer;
}

.main_center_container_mobile 
.calendar_page_seasons_season > span {
    font-size: 13px;
}

.calendar_page_seasons_season_key_choosed {
    color: rgb(0, 57, 83) !important;
    cursor: default !important;
    font-weight: bold;
}

.calendar_page_upper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    min-height: 320px;
}

.main_center_container_mobile 
.calendar_page_upper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    min-height: 320px;
}


.calendar_page_upper
.data_changer_container {
    padding: 0px;
}

.calendar_page_upper_left {
    width: 330px;
    min-width: 330px;
    padding-left: 50px;
}

.main_center_container_mobile
.calendar_page_upper_left {
    padding-left: 20px;
}


.calendar_page_upper_right {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    margin-right: 48px;
}
.main_center_container_mobile
.calendar_page_upper_right {
    width: 80%;
    max-height: 300px;
    overflow-y: auto;
    /* margin-right: 48px; */
    margin: 0px;
    overflow-x: hidden;
}

.main_center_container_mobile
.calendar_page_upper_right 
.inlarge_event_row_title a {
    font-size: 11px;
}

.main_center_container_mobile
.calendar_page_upper_right 
.inlarge_event_row_title {
    overflow-x: visible;
    white-space: unset;
    line-height: 12px;
}

.main_center_container_mobile
.calendar_page_upper_right 
.event_big_plashka__status_text {
    line-height: 22px;

}



.calendar_page_months {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    flex-wrap: wrap;
    grid-row-gap: 15px;
    row-gap: 15px;

}
.rectAvatar {
    width: 40px;
    height: 60px;

    /* background: linear-gradient( rgba(245, 245, 245, 0.349), rgba(255, 255, 255, 0.534)); */
    /* border: 1px solid rgb(202, 202, 202); */
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */

    display: flex;
    flex-direction: column;
    transition-duration: 1s;

    cursor: pointer;
    position: relative;
}

.rectAvatarSelected {
    -webkit-filter: drop-shadow( 0px 0px 6px rgba(87, 215, 231, 0.801) );
            filter: drop-shadow( 0px 0px 6px rgba(87, 215, 231, 0.801) )
}

.rectAvatar:hover {
    -webkit-filter: drop-shadow( 0px 0px 6px rgba(255, 196, 0, 0.801) );
            filter: drop-shadow( 0px 0px 6px rgba(255, 196, 0, 0.801) )
}

.rectAvatar_upper {
    width: 100%;
    height: 70%;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rectAvatar_lower {
    width: 100%;
    height: 30%; 
    max-height: 30%;  
    overflow: hidden; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rectAvatar_lower_text {
    color: black;
    font-size: 90%;
    font-family: 'Uni';
    text-align: center;
}

.rectAvatar_upper_absolute {
    position: absolute;
    color: rgb(94, 128, 167);
    font-family: 'Uni';
    width: 100%;
    top: 22%;
    left: 0%;
    text-align: center;
}
.school_big_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    background: rgb(243, 243, 243);
    display: flex;
    flex-direction: row;
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    position: relative;
    overflow: hidden;
}

.school_big_plashka___avatar_back {
    position: absolute;
    opacity: 0;
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
    left: -30%;
    -webkit-animation: 'school_avatar_back' 1s;
            animation: 'school_avatar_back' 1s;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes school_avatar_back {
    from {
        left: -30%;
        opacity: 0;
    }
    1% {
        opacity: 0;
    }
    to {
        left: -10%;
        opacity: 0.2;
    }
}

@keyframes school_avatar_back {
    from {
        left: -30%;
        opacity: 0;
    }
    1% {
        opacity: 0;
    }
    to {
        left: -10%;
        opacity: 0.2;
    }
}

.school_big_plashka___avatar_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  
}

.school_big_plashka___info_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    position: relative; 
}

.school_big_plashka___info_container___name {
    text-align: left; 
    width: 100%;
    height: 14%;
    min-height: 14%;
    font-size: 47px;
    font-family: 'MyriadPro';
    margin-top: 40px;
}

.main_center_container_mobile .school_big_plashka___info_container___name {
    height: 30px;
    min-height: 30px;
    font-size: 31px;
    margin-top: 16px;
}


.school_big_plashka___info_container___info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  
    width: 100%;
    height: 12%;
    min-height: 12%;
    font-size: 22px;
    font-family: 'MyriadPro';
}

.school_big_plashka___info_container___school_group_cordao {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;  
    width: 100%;
    height: 66%;
    min-height: 66%;
}

.school_big_plashka___info_container___school_group_cordao 
.rectAvatar {
    margin-right: 40px;
}
.ui_item__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ui_item__icon {
    height: 50%;
    width: 50%;
}

.ui_item__icon img {
    width: 100%;
    height: 100%;
}

.ui_item__text {
    margin-top: 5px;
    height: 20%;
    text-align: center;
    font-family: 'MyriadPro';
    color: rgb(71, 71, 71);
}

.ui_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    /* border: 1px solid gray; */

    overflow-y: auto;

}

.main_center_container_mobile .ui_plashka{
    justify-content: space-around;
    align-items: center;

}
.school_raiting_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    background: rgb(243, 243, 243);
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    border-radius: 15px;
}

.school_raiting_plashka-switch-item {
    cursor: pointer;
    color: gray;
    margin-left: 10px;
}


.main_center_container_mobile 
.school_raiting_plashka-switch-item {
    font-size: 12px;
}

.school_raiting_plashka-switch-item__selected {
    color: rgb(5, 56, 80);
}

.school_raiting_plashka__oneraiting {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.school_raiting_plashka__oneraiting__place_container {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Uni';
    /* background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80)); */
    color: rgb(0, 0, 0);
}

.school_raiting_plashka__oneraiting__place_container_left {
    height: 90%;
    border-radius: 20%;
    text-align: center;
    line-height: 130%;
    margin-right: 5%;
}

.school_raiting_plashka__oneraiting__place_container_middle {    
    line-height: 120%;
}
.school_raiting_plashka__oneraiting__place_container_down
{
    height: 20%;
}

.school_raiting_plashka__oneraiting__place_container_left_place_1 {
    border: 2px solid gold;
    background: linear-gradient(rgb(255, 136, 0), gold);
    color: rgb(255, 255, 255)
}

.school_raiting_plashka__oneraiting__place_container_left_place_2 {
    border: 2px solid rgb(255, 255, 255);
    background: linear-gradient(rgb(255, 255, 255), rgb(205, 214, 216));
    color: rgb(92, 92, 92)
}

.school_raiting_plashka__oneraiting__place_container_left_place_3 {
    border: 2px solid #cd7f32;
    background: linear-gradient(rgb(248, 150, 37), #cd7f32);
    color: rgb(255, 255, 255)
}
.medal_shalve_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    /* border: 1px solid gray; */

    background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80));
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    border-radius: 10px;

    overflow-y: auto;

}

.medal_shalve_plashka_ascount {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    overflow-y: hidden;
}

.medal_shalve_plashka_medal_ascount > span {
    font-size: 15px !important;
}
.ui_chooser_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    /* border: 1px solid gray; */

    overflow-y: auto;

}

.ui_chooser_plashka_mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    overflow-y: hidden;
}

.ui_chooser_plashka_raitingmobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
    height: 35px;
    max-height: 35px;
}

.ui_chooser_plashka a {
    width: 100%;
}

.ui_chooser_plashka_item {
    font-size: 17px;
    font-family: 'MyriadPro';
    color: rgb(70, 70, 70);
    font-weight: 100;
    /* background: white;
    border-bottom: 1px solid rgb(228, 228, 228); */
    width: 100%;
    min-width: 100%;
    padding: 3px;
    padding-left: 6px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    grid-column-gap: 5px;

    -webkit-column-gap: 5px;

            column-gap: 5px;

}

.ui_chooser_plashka_item_mobile {
    padding: 0px !important;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.ui_chooser_plashka_item_mobile_selected {
    color: white;
}

.ui_chooser_plashka_item_mobile_up {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 50px;
    height: 50px;

    border-radius: 10px;
    /* background:rgb(221, 221, 221);  */
    background: linear-gradient(rgb(235, 235, 235), rgb(221, 221, 221));

    transition-duration: 0.5s;
}

.ui_chooser_plashka_item_mobile_selected
.ui_chooser_plashka_item_mobile_up {
    background:rgb(1, 87, 128); 
    background: linear-gradient(rgb(1, 87, 128), rgb(0, 53, 78));
}

.ui_chooser_plashka_item_mobile_down {
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    height: 20px;
    font-family: 'MyriadPro';
    vertical-align: middle;
    color: rgb(70, 70, 70);
}

.ui_chooser_plashka_raitingmobile
.ui_chooser_plashka_item_mobile_down {
    font-size: 12px;
    line-height: 12px;
    font-family: 'MyriadPro';
    width: 40px;
}

.ui_chooser_plashka_raitingmobile
.ui_chooser_plashka_item_mobile_selected{
    font-weight: bold;
}


.ui_chooser_plashka_item:hover {
    background:rgb(231, 231, 231); 
}

.ui_chooser_plashka_item_selected {
    /* color: rgb(5, 56, 80); */
    color: white;
    background:rgb(1, 87, 128); 
}

.ui_chooser_plashka_item_selected:hover {
    background:rgb(1, 87, 128); 
}
.raiting_subject_plashka{
    width: 100%;
    height: 100%;
    padding-top: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    font-family: 'MyriadProUsual';

    /* background:  white;
    padding: 20px;
    border-radius: 5px; */


}

.main_center_container_mobile .raiting_subject_plashka {
    padding: 0px;
}

.raiting_subject_plashka__oneraiting {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
    
    /* background: rgb(243, 243, 243);
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */

    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(207, 207, 207);

    transition-duration: 0.5s;
    font-family: 'PFD';
    font-weight: 100;
    text-transform: uppercase;

}

.raiting_subject_plashka__oneraiting_row {
   -webkit-animation: 0.7s one_row_anim forwards ease-in-out;
           animation: 0.7s one_row_anim forwards ease-in-out;
}

@-webkit-keyframes one_row_anim {
    from {
        margin-left: 30px;
        opacity: 0;
    }
    to {
        margin-left: 0px;
        opacity: 1;
    }
}

@keyframes one_row_anim {
    from {
        margin-left: 30px;
        opacity: 0;
    }
    to {
        margin-left: 0px;
        opacity: 1;
    }
}


.raiting_subject_plashka__oneraiting_row_selected,
.raiting_subject_plashka__oneraiting_row:hover {
    background: white;
    cursor: pointer;
}



.raiting_subject_plashka__oneraiting_header {
    height: 20px;
    font-family: 'Uni';
    border-bottom: 1px solid rgb(110, 110, 110);
}

.raiting_subject_plashka__oneraiting_place {
    width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.main_center_container_mobile .raiting_subject_plashka__oneraiting_place {
    /* width: 80px; */
}

.raiting_subject_plashka__oneraiting_header
.raiting_subject_plashka__oneraiting_place {
    justify-content: flex-start;

    padding-left: 10px;
}

.raiting_subject_plashka__oneraiting_place_inside {
    width: 45px;
}

.raiting_subject_plashka__oneraiting_category {
    width: 100%;
    padding-left: 20px;
    line-height: 15px;
}

.raiting_subject_plashka__oneraiting_scores {
    width: 100px;
}
.cordao_subject_table {
    width: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-row-gap: 20px;
    row-gap: 20px;

    overflow-y: auto;
    height: -webkit-fit-content !important;
    height: fit-content !important;

    /* background: rgb(243, 243, 243);
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    /* border-radius: 15px; */
}

.main_center_container_mobile .cordao_subject_table {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
    grid-row-gap: 5px;
    row-gap: 5px;
    justify-content: flex-start;

}

.cordao_subject_table-separator {
    height: 0px;
    margin-bottom: 10px;
    width: 100%;
    color: rgb(5, 56, 80);
    font-family: 'MyriadPro';
    font-size: 20px;
}

.main_center_container_mobile .cordao_subject_table-separator {
    height: 20px;
    margin-bottom: 3px;
    font-size: 17px;
}

.cordao_subject_table-switch-item {
    cursor: pointer;
    color: gray;
    margin-left: 10px;
}

.cordao_subject_table-switch-item__selected {
    color: rgb(5, 56, 80);
}

.cordao_subject_table__oneraiting {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cordao_subject_table__oneraiting__place_container {
    border: 2px solid rgb(5, 56, 80);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Uni';
    background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80));
    color: white;
}

.cordao_subject_table__oneraiting__place_container_upper {
    height: 45%;
}

.cordao_subject_table__oneraiting__place_container_middle,
.cordao_subject_table__oneraiting__place_container_down
{
    height: 20%;
}

.subject_layout_container {
    overflow-y: auto;
    overflow-x: hidden;
}
.group_big_plate {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    background: linear-gradient(rgb(240, 240, 240), rgb(226, 226, 226));
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */

    border: 1px solid rgb(214, 214, 214);

    border-top-left-radius: 35px; 
    border-bottom-right-radius: 20px; 

    padding-left: 15px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;

    transition-duration: 0.6s;
}

.group_big_plate:hover {
    background: white;
    cursor: pointer;
}

.group_big_plate_name {
    width: 50%;
    font-size: 19px;
    font-family: 'PFD';
    text-transform: uppercase;
    color: rgb(5, 56, 80);
}

.main_center_container_mobile .group_big_plate_name {
    font-size: 17px;
    line-height: 16px;
}

.group_big_plate_medals_inside {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 

    grid-column-gap: 5px; 

    -webkit-column-gap: 5px; 

            column-gap: 5px;
}
.groups_big_list_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 1px solid gray; */

    overflow: visible;

}
.school_new_news_plashka {
    width: 100%;
    height: 100%;
    background: rgb(253, 253, 253);
    
    display: flex;
    flex-direction: row;

    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );

            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    padding: 10px;

}

.school_new_news_plashka_nonews {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: 'PFD';
    color: rgb(158, 158, 158);
}

.school_new_news_plashka_nonews span {
    text-align: center;
}
.school_page {
    height: -webkit-fit-content;
    height: fit-content;
}
.ain-medal-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.medal-div {
  position: relative;
}

.medal-div-canvas {

}

.medal-div-with-count {
  width: 50%;
  height: 38%;

  border-radius: 15px;

  border: 2px solid rgb(105, 143, 150);
  background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80));

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  top: 70%;
  left: 25%;

  position: absolute;
  color: white;
}

.medal-div-with-count > span {
  font-family: 'PFD';
  font-size: 320%;

}

.medal-div h1 {
  margin-top: 5vw;
  font-size: 4.5vw !important;
  line-height: 4vw !important;
  color: white !important;
  font-family: 'MyriadPro';
}

.medal-div h3 {
  margin-top: 0px;
  font-size: 2.5vw !important;
  line-height: 2vw !important;
  color: rgb(0, 123, 160) !important;
  font-family: 'MyriadPro';
}

/*      СТАТИСТИКА      */
.person_big_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );

    border-top-left-radius: 30px;

    overflow: hidden;

    position: relative;

    border-radius: 40px;
    
}

.person_big_plashka_rank_gold {
    background: linear-gradient(rgb(255, 153, 1), rgb(58, 0, 44));
    -webkit-filter: drop-shadow( 0px 0px 20px rgba(255, 225, 89, 0.767) );
            filter: drop-shadow( 0px 0px 20px rgba(255, 225, 89, 0.767) );
}

.person_big_plashka_rank_silver {
    background: linear-gradient(rgb(194, 193, 193), rgb(82, 82, 82));
    -webkit-filter: drop-shadow( 0px 0px 5px rgb(255, 255, 255) );
            filter: drop-shadow( 0px 0px 5px rgb(255, 255, 255) );
    border: 6px solid white;
}

.person_big_plashka_rank_bronze {
    background: linear-gradient(rgb(230, 159, 119), rgb(216, 93, 35));
    -webkit-filter: drop-shadow( 0px 0px 5px rgb(245, 177, 132) );
            filter: drop-shadow( 0px 0px 5px rgb(245, 177, 132) );
    border: 2px solid rgb(241, 145, 107);
}

.bgbgbg {
    position: absolute;
    top: 0px;
    left: 0px;
}

.person_big_plashka___avatar_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  
}

.person_big_plashka___info_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start; 
    position: relative; 
}

.person_big_plashka___info_container___name {
    text-align: left; 
    width: 100%;
    /* height: 14%; */
    /* min-height: 14%; */
    font-size: 34px;
    font-family: 'MyriadPro';
}

.person_big_plashka___info_container___info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;  
    width: 100%;
    height: 14%;
    min-height: 14%;
    font-size: 22px;
    font-family: 'MyriadPro';
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
            column-gap: 8px;
}

.person_big_plashka___info_container___school_group_cordao {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;  
    width: 100%;
    height: 66%;
    min-height: 66%;
}

.person_big_plashka___info_container___school_group_cordao 
.rectAvatar {
    margin-right: 40px;
}
.person_big_plashka_mob {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    background: linear-gradient(rgb(235, 235, 235), white);
    /* background: rgb(255, 255, 255); */
    display: flex;
    flex-direction: row;
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    padding-top: 20px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;

    position: relative;
    overflow: hidden;
}

.person_big_plashka_mob_rank_gold {
    background: linear-gradient(rgb(255, 153, 1), rgb(58, 0, 44));
    -webkit-filter: drop-shadow( 0px 0px 8px rgba(255, 225, 89, 0.767) );
            filter: drop-shadow( 0px 0px 8px rgba(255, 225, 89, 0.767) );
}

.person_big_plashka_mob_rank_silver {
    background: linear-gradient(rgb(194, 193, 193), rgb(82, 82, 82));
    -webkit-filter: drop-shadow( 0px 0px 8px rgb(255, 255, 255) );
            filter: drop-shadow( 0px 0px 8px rgb(255, 255, 255) );
    border: 2px solid white;
}

.person_big_plashka_mob_rank_bronze {
    background: linear-gradient(rgb(230, 159, 119), rgb(216, 93, 35));
    -webkit-filter: drop-shadow( 0px 0px 8px rgb(245, 177, 132) );
            filter: drop-shadow( 0px 0px 8px rgb(245, 177, 132) );
    border: 2px solid rgb(241, 145, 107);
}

.bgbgbg {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1 !important;
}

.pbp_mobile_right_left { 
    margin-left: 20px;
    margin-right: 20px;
}

.pbp_mobile_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    margin-right:10px;
}

.pbp_mobile_name {
    font-size: 20px;
    font-family: 'MyriadPro';
    padding-right:20px;
    line-height: 20px;
}

.pbp_mobile_social {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; 
    grid-column-gap: 10px; 
    -webkit-column-gap: 10px; 
            column-gap: 10px;
    padding-right:20px;
}

.pbp_mobile_info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: auto;
}


.person_raiting_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    background: white;
    display: flex;
    flex-direction: column;
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.281));
            filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.281));
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;
    /* padding-left: 15px;
    padding-right: 15px; */
    grid-row-gap: 20px;
    row-gap: 20px;
}

.person_raiting_plashka 
.numberText {
    width: 100px !important;
}

.person_raiting_plashka_oneseason {
    display: flex;
    flex-direction: column;
}

.person_raiting_plashka_season {
    font-size: 16px;
    color: rgb(5, 56, 80);
    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 15px;
}

.person_raiting_plashka_raiting {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-row-gap: 5px;
    row-gap: 5px;
}


.person_raiting_plashka_raiting_one_row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center; 
    width: 100%;

    height: 56px;
    background: linear-gradient( 90deg, rgb(230, 230, 230), white)
}

.main_center_container_mobile
.person_raiting_plashka_raiting_one_row {
    height: 40px;
}

.person_raiting_plashka_raiting_one_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    width: 220px;
}

.main_center_container_mobile
.person_raiting_plashka_raiting_one_info {
    width: 80px;
}


.person_raiting_plashka_raiting_one_devider {
    width: 2px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.person_raiting_plashka_raiting_one_devider_inside {
    width: 1px;
    height: 70%;
    background: rgb(5, 56, 80);
    -webkit-transform: skew(-20deg);
            transform: skew(-20deg);
}
.person_video_big_plashka {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    background: none;
    display: flex;
    flex-direction: row;

    justify-content: space-around;

    flex-wrap: wrap;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;

    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );

            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;
}
.person_raiting_history_plashka {
}

.person_raiting_history_plashka__oneraiting {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
    
    /* background: rgb(243, 243, 243);
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */

    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(207, 207, 207);

    transition-duration: 0.5s;
    font-family: 'PFD';
    font-weight: 100;
    text-transform: uppercase;

}

.person_raiting_history_plashka__oneraiting_row {
   -webkit-animation: 0.7s one_row_anim forwards ease-in-out;
           animation: 0.7s one_row_anim forwards ease-in-out;
}

@-webkit-keyframes one_row_anim {
    from {
        margin-left: 30px;
        opacity: 0;
    }
    to {
        margin-left: 0px;
        opacity: 1;
    }
}

@keyframes one_row_anim {
    from {
        margin-left: 30px;
        opacity: 0;
    }
    to {
        margin-left: 0px;
        opacity: 1;
    }
}

.person_raiting_history_plashka__blank {
    width: 100%;
    height: 20px;
}

.person_raiting_history_plashka__oneraiting_row_selected,
.person_raiting_history_plashka__oneraiting_row:hover {
    background: white;
}



.person_raiting_history_plashka__oneraiting_header {
    height: 20px;
    font-family: 'Uni';
    border-bottom: none;
    /* border-bottom: 1px solid rgb(110, 110, 110); */
}

.main_center_container_mobile
.person_raiting_history_plashka__oneraiting_header {
    font-size: 10px !important;
}

.person_raiting_history_plashka__oneraiting_avatar {
    width: 40px;
    padding-left: 20px;
}

.main_center_container_mobile .person_raiting_history_plashka__oneraiting_avatar {
    width: 15px;
    padding-left: 4px;
}

.person_raiting_history_plashka__oneraiting_competition {
    width: 440px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 20px;
}

.main_center_container_mobile 
.person_raiting_history_plashka__oneraiting_competition {
    white-space: unset !important;

    font-size: 9px !important;
}

.person_raiting_history_plashka__oneraiting_season {
    color: rgb(5, 56, 80);
}

.person_raiting_history_plashka__oneraiting_category {
    width: 190px;
    line-height: 14px;
}

.main_center_container_mobile .person_raiting_history_plashka__oneraiting_category {
    width: 190px;
    line-height: 11px;
    font-size: 9px !important;
}

.person_raiting_history_plashka__oneraiting_scores {
    width: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.person_raiting_history_plashka__oneraiting_place {
    width: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 50px;
    margin-right: 3px;
}

.person_raiting_history_plashka__oneraiting_place_inside {
    width: 45px;
}

.main_center_container_mobile 
.person_raiting_history_plashka__oneraiting_place_inside {
    width: 22px;
    font-size: 10px;
}

.person_raiting_history_plashka__oneraiting_header
.person_raiting_history_plashka__oneraiting_place {
    justify-content: flex-start;

    padding-left: 10px;
}
.person_data_change {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
    /* font-family: 'Uni'; */
    color: rgb(5, 56, 80);

    
    font-family: 'PFD';

}

.person_data_change_tabs .ant-tabs-tab {
    color: rgb(5, 56, 80);
    text-transform: uppercase;
}

.person_data_change_tabs .ant-tabs-tab-active {
    color: #1890ff;
}

.person_data_change_tabs .ant-tabs-left-content {
    color: black;
}
.image_uploader_container_header {
    color: rgb(5, 56, 80);
    font-size: 18px;
    margin-bottom: 10px;
    color: rgb(90, 90, 90); 
}

.image_uploader_container_title {
    font-size: 17px;
    color: rgb(5, 56, 80);

    margin-top: 10px;
    margin-bottom: 3px;

}

.image_uploader_container_text {
    color: rgb(90, 90, 90);
    font-size: 12px;
}

.image_uploader_changer {
    margin-bottom: 15px;
}
.application_little {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(196, 196, 196);
    /* background: rgb(0, 28, 51); */
    /* background: linear-gradient(rgb(0, 29, 46), rgb(1, 43, 68)) */
    background: rgb(255, 255, 255);

    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    margin-left: 0px;

    /* image-rendering: pixelated; */
    /* image-resolution: 3200; */
    image-rendering: optimizeSpeed;

    grid-column-gap: 30px;

    -webkit-column-gap: 30px;

            column-gap: 30px;

}

.application_little:last-child {
    border-bottom: none;
}

.application_little_loading {
    background: none;
    opacity: 0.5;
    margin-left: 20px;
}

.application_little__avatar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.application_little__cordao {
    position: absolute;
    top: 50%;
    left: 45%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

}

.application_little__fio {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    font-family: 'MyriadPro';
    margin-bottom: 15px;
    /* color: white; */
}

.application_little__suffix {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: fit-content;
}

.application_little__school {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.sport_changer_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    padding: 30px;
    padding-top: 10px;
    font-family: 'PFD';

    grid-column-gap: 30px;

    -webkit-column-gap: 30px;

            column-gap: 30px;

}

.sport_changer_header {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    color: rgb(90, 90, 90);
    font-size: 18px;
    margin-bottom: 30px;
}

.sport_changer_devider {
    width: 100%;
    height: 1px;
}

.sport_changer_component {
    width: 250px;
    height: -webkit-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.sport_changer_component_title {
    font-size: 17px;
    color: rgb(5, 56, 80);
}

.sport_changer_component_comp {
    color: rgb(48, 48, 48);
}

.sport_changer_component_text {
    color: rgb(90, 90, 90);
    font-size: 12px;
}

.sport_changer_footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}
.person_inventar_container {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;

    /* font-family: 'Uni'; */
    color: rgb(5, 56, 80);

    font-family: 'PFD';

    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    /* min-height: 300px; */
}

.person_inventar_container_filter {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.person_inventar_container_mobile
.person_inventar_container_filter
{
    width: 100px;
}

.person_inventar_container_filter_types {
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    grid-row-gap: 4px;
    row-gap: 4px;
}

.person_inventar_container_mobile
.person_inventar_container_filter_types {
    width: 30px;
}


.person_inventar_container_filter_type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 20%;

    background: none;
    transition-duration: 0.5s;
    cursor: pointer;
}

.person_inventar_container_filter_type:hover {
    background: rgb(212, 212, 212);
}

.person_inventar_container_filter_type_selected {
    background: rgb(182, 182, 182);
}

.person_inventar_container_items {
    /* background: linear-gradient(rgb(145, 145, 145), rgb(172, 172, 172)); */
    border-radius: 5px;

    width: 760px;
    height: 500px;
    padding-top: 10px;

    overflow-y: auto;

}

.person_inventar_container_mobile 
.person_inventar_container_items {
    height: 350px;
}

.person_inventar_container_items_inside {
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    align-items: flex-start;
    flex-wrap: wrap;

    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;

    grid-row-gap: 12px;

    row-gap: 12px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.person_inventar_blank_item {
    background: rgb(141, 153, 160);
    border-radius: 5px;
}

.person_data_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;
}

.person_data_plasha_oneplashka {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-column-gap: 14px;
    -webkit-column-gap: 14px;
            column-gap: 14px;
    margin-left: 10px;
}

.person_data_plasha_oneplashka_kubok {
    margin-top: 7px;
    margin-bottom: 7px;

    padding-left: 20px;
}

.person_data_plasha_oneplashka_clicked {
    cursor: pointer;
}

.person_data_plasha_oneplashka_left {
    width: 60px;
    height: 60px;    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_data_plasha_oneplashka_left img {
    width: 50px;
    height: 50px;
}

.person_data_plasha_oneplashka_right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 12px;
}

.person_data_plasha_oneplashka_right_upper {
    width: 100%;
    height: 16px;
    font-size: 14px;
    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(5, 56, 80);
}

.person_data_plasha_oneplashka_right_downed {
    width: 100%;
    height: 20px;
    font-size: 21px;
    font-family: 'MyriadPro';
}

.person_data_plasha_devider {
    width: 100%;
    height: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}

.person_data_plasha_devider_line {
    width: 100%;
    height: 1px;
    background: rgba(5, 56, 80, 0.438);
}

.person_data_plasha_devider_text {
    width: -webkit-fit-content;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    height: 16px;

    font-size: 11px;
    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(5, 56, 80);
}

.person_data_status_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
            column-gap: 8px;
}

.person_data_scoresstatus {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
}

.person_data_scoresstatus img {
    width: 100%;
    height: auto;
}

.person_data_scoresstatus .ant-progress-text {
    font-family: 'Uni';
    font-size: 14px;
}

.person_data_moneystatus {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
}

.person_data_moneystatus img {
    width: 60%;
    height: auto;
}

.person_data_moneystatus .person_data_moneystatus_amount {
    font-family: 'Uni';
    font-size: 14px;
}


.person_data_kubok_1 {
    position: relative;
    width: 100px;
    height: 100px;

    margin-right: 20px;
}

.person_data_kubok_2 {
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.person_data_kubok_3 {
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.person_data_kubok_img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    
}

.person_data_kubok_1 img{
    width: 70px;
    height: 70px;    
    -webkit-filter: drop-shadow( 0px 0px 2px rgb(255, 196, 0) );    
            filter: drop-shadow( 0px 0px 2px rgb(255, 196, 0) );
    border-radius: 20%;
    /* border: 0px solid rgb(197, 197, 197); */
}

.person_data_kubok_2 img{
    width: 65px;
    height: 65px;    
    -webkit-filter: drop-shadow( 0px 0px 2px rgb(190, 190, 190) );    
            filter: drop-shadow( 0px 0px 2px rgb(190, 190, 190) );
    border-radius: 20%;
    /* border: 0px solid rgb(197, 197, 197); */
}

.person_data_kubok_3 img{
    width: 60px;
    height: 60px;    
    -webkit-filter: drop-shadow( 0px 0px 2px rgb(179, 151, 115) );    
            filter: drop-shadow( 0px 0px 2px rgb(179, 151, 115) );
    border-radius: 20%;
    /* border: 0px solid rgb(197, 197, 197); */
}

.person_data_kubok_shine {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 100;

}
.person_video_mini_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    background: none;
    display: flex;
    flex-direction: column;
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;
}
.event_block_admission {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;

    border: 1px solid rgb(231, 231, 231);


    border-radius: 5px; 
}

.event_block_admission_check {
    background: linear-gradient(rgb(224, 252, 224), lightgreen);
    border: 2px solid rgb(46, 199, 46);
}

.event_block_admission_cross {
    background: linear-gradient(rgb(252, 233, 224), rgb(238, 144, 144));
    border: 2px solid rgb(184, 28, 28);
}

.event_block_admission_upper {
    height: 50%;
}

.event_block_admission_downed {
    font-size: 13px;
    height: 50%;
    line-height: 13px;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main_center_container_mobile .event_block_admission_downed {
    font-size: 9px;
    height: 50%;
    line-height: 9px;
}

.event_block_admission_clicked {
    cursor: pointer;
}
.main_center_container_mobile
.person_my_events_plashka
.global_plashka_no_data {
    /* justify-content: flex-end; */
    padding: 0px !important;
    padding-top: 5px !important;
}
.item_onwall_components {
    position: relative;
    width: 100%;
    height: 100%;
}

.item_onwall_components img {
    width: 100%;
    height: 100%;
}

.item_onwall_components_event {
    position: absolute;
}

.item_onwall_components_event_shine {
    position: absolute;
    top: -18px;
    left: -10px;
}
.person_wall_container {
    border: 1px solid rgb(197, 197, 197);
    
    /*DEFAULT*/
    background: linear-gradient(#d4d4d4, #b4b4b4);

    background-size: cover !important;
    border-radius: 20px;
    position: relative;
}

.person_wall_cell {
    position: absolute;
    border: 1px solid rgba(156, 156, 156, 0.603);
    border-radius: 5%;
    z-index: 10;
}

.person_wall_cell_inventar {
    position: absolute;
    border: 1px solid rgba(156, 156, 156, 0.603);
    border-radius: 5%;
    z-index: 10;
}

.person_wall_cell_inventar:hover {
    background: rgba(0, 128, 0, 0.575);
}


.person_wall_cell_draggable {
    position: absolute;
    /* border: 1px solid rgba(83, 182, 2, 0.603); */
    border: none;
    border-radius: 5%;
    cursor: pointer;

    background-image: url(/static/media/hand.19b16c61.png);
    background-size: cover;

    /* z-index: 1100; */
}

.person_wall_cell_todrop {
    width: 100%;
    height: 100%;
}

.person_wall_cell_candrop {
    width: 100%;
    height: 100%;
    background: rgba(0, 128, 0, 0.575);
}

.person_wall_item {
    position: absolute;
}

.person_wall_cell_draggable {
    visibility: hidden;
}

.person_wall_item:hover .person_wall_cell_draggable {
    visibility: visible;
}

.person_wall_item img {
    width: 100%;
    height: 100%;
}

.person_wall_downbutton {
    border-radius: 50%;
    background: radial-gradient(white, rgba(255, 255, 255, 0) 70%);
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    font-family: 'UNI';
    padding-top: 2%;

    transition-duration: 0.6s;
    opacity: 0.3;
    color: black;

    cursor: pointer;
}

.person_wall_downbutton:hover {
    color: white;
    opacity: 1;
}

.person_wall_downbutton_godrop {
    background: radial-gradient(rgb(240, 72, 72), rgba(87, 0, 0, 0) 70%);
    color: white;
    opacity: 1;
}

.person_wall_upbutton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition-duration: 0.6s;
    opacity: 0.8;
    color: rgb(185, 185, 185);

    position: absolute;
    z-index: 900;

    font-family: 'PFD';
    letter-spacing: 3px;
    font-size: 20px;

    cursor: pointer;
    background: rgb(0, 44, 94);
    ;
}

.person_wall_upbutton:hover {
    color: rgb(255, 255, 255);
    opacity: 1;
}


.person_wall_choose_mode {
    background: rgba(255, 255, 255, 0.616);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    position: absolute;
    z-index: 999;

    align-content: flex-start;
    overflow-y: auto;

}

/* .person_wall_choose_mode_dragged {
    z-index: 0;
    background: none;
} */

.person_wall_choose_mode_cross {
    cursor: pointer;
    position: absolute;
    left: calc(100% - 20px);
    transition-duration: 0.5s;
    z-index: 1200;
}

.person_wall_choose_mode_cross:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}
.person_wall {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(255, 255, 255); */
    display: flex;
    flex-direction: row;
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    /* padding-top: 20px; */
    /* border-radius: 30px; */

    position: relative;
    overflow: hidden;
}


.person_fkraccred_plashka {
    width: 100%;
    height: 100%;
    background: rgb(253, 253, 253);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );

            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    position: relative;
    overflow: hidden;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;

    border-radius: 10px;
    border: 1px solid rgb(92, 92, 92);

}

.person_fkraccred_plashka_top {
    font-family: 'UNI';
    color: #053850;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
    text-align: right;
}

.person_fkraccred_plashka_top img {
    width: 25px;
    height: 25px;
}

.person_fkraccred_plashka_top_payment
.event_block_admission_downed {
    font-size: 10px;
}

.person_fkraccred_plashka_top > span {
    max-width: 180px;
    line-height: 20px;
}

.person_fkraccred_plashka_bottom {
    font-family: 'PFD';
    font-size: 14px;
    cursor: pointer;
}

.person_fkraccred_plashka_bottom:hover {
    color: #053850;
}

.person_fkraccred_plashka_modal {
    width: 800px;
}

.person_fkraccred_plashka_modal_container {
    width: 100%;
    padding: 20px;
    min-height: 300px;
}

.person_fkraccred_plashka_modal_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #053850;
    font-size: 18px;
    font-family: 'PFD';

    grid-row-gap: 15px;

    row-gap: 15px
}
.element-card {
 
    width: 100px;
    height: 120px;

    font-size: 10px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start; 
    
    border: 1px solid gray;
    border-radius: 0.3vw;

    position: relative;
    
    -webkit-animation: shadow-card 0.5s infinite ease-in-out;
    
            animation: shadow-card 0.5s infinite ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;

    /* transform: translateY(-30.0px) perspective(1000px) rotateX(44deg); */
    -webkit-transform:  perspective(500px) rotateX(44deg);
            transform:  perspective(500px) rotateX(44deg);

    opacity: 0;
    
    background: rgb(0, 16, 36);

}

.element-card-usual {
  border: 2px solid white;
  background: rgb(0, 45, 63);
}

.element-card-usual_lvl1 {
  background: linear-gradient(rgb(116, 75, 0), rgb(36, 0, 0));
}
.element-card-usual_lvl2 {
  background: linear-gradient(rgb(151, 151, 151), rgb(54, 54, 54));
}

.element-card-usual_lvl3 {
  background: linear-gradient(rgb(170, 130, 0), rgb(85, 69, 0));
}



@-webkit-keyframes shadow-card {
    from {
      color: rgb(219, 212, 146);
      -webkit-transform:  perspective(500px) rotateX(85deg);
              transform:  perspective(500px) rotateX(85deg);
      opacity: 0.9;
    }
    to {
      color: white;
      -webkit-transform:  perspective(500px) rotateX(0deg);
              transform:  perspective(500px) rotateX(0deg);
      /* transform:  perspective(0px) rotateX(0deg); */
      opacity: 1.0;
    }
  }



@keyframes shadow-card {
    from {
      color: rgb(219, 212, 146);
      -webkit-transform:  perspective(500px) rotateX(85deg);
              transform:  perspective(500px) rotateX(85deg);
      opacity: 0.9;
    }
    to {
      color: white;
      -webkit-transform:  perspective(500px) rotateX(0deg);
              transform:  perspective(500px) rotateX(0deg);
      /* transform:  perspective(0px) rotateX(0deg); */
      opacity: 1.0;
    }
  }




.element-card-title {

    width: 100%;
    text-align: center;

}

.element-card-upper {

    position: absolute;

    z-index: 10;

    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;
    opacity: 0.0;
    background: rgba(6, 31, 53, 0.87);
    
    transition-duration: 0.5s;
    
    border-radius: 0.3vw;
    
    visibility: hidden;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
  }
  
  .element-card:hover .element-card-upper {
  
    visibility: visible;
    opacity: 1;
    transition-duration: 0.5s;
  
  }

  .element-card-poopover {
      width: 300px;
  }

  .element-card-poopover-mobile {
    width: 100px;
}

  .element-card-upper-icon {
      font-size: 2.5vw !important;
      margin: 0.4vw !important;
      color: gray !important;
      cursor: pointer;
      transition-duration: 0.2s;
  }

  .element-card-upper-icon:hover {
    font-size: 2.8vw !important;
    margin: 0.35vw !important;
    color: white !important;
    transition-duration: 0.2s;
}

  .element-card-upper-icon-disabled {
    font-size: 2.5vw !important;
    margin: 0.4vw !important;
    color: gray !important;
    cursor: not-allowed;
  }

  .element-mobile-icon {
    font-size: 5.5vw !important;
  }

  .element-card-bottom_line {
    position: absolute;

    z-index: 1;

    top: 86%;
    left: 0px;

    width: 100%;
    height: 10%;

    padding-left: 10%;
    padding-right: 10%;

    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: linear-gradient(rgb(1, 25, 53), rgb(0, 31, 66));

    font-family: 'MyriadPro';
}

.element-card-bottom_line_otkat {
  position: absolute;

  z-index: 1;

  top: 30%;
  left: 0px;

  width: 100%;
  height: 20%;

  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 170%;

  color: red;

  background: rgba(0, 0, 0, 0.521);

  font-family: 'MyriadPro';
}

.element-card-bottom_line-changes {
  position: absolute;

  z-index: 1;

  top: 66%;
  left: 0px;

  width: 100%;
  height: 30%;

  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: center;

  background: linear-gradient(rgb(1, 25, 53), rgb(0, 31, 66));

  font-family: 'MyriadPro';

  padding-top: 10px !important;

  text-align: center;

  font-size: 150%;

}

/* Тут у нас картинка сама и т.д. */
.element-card-content {

  position: absolute;

  z-index: 2;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start; 

  overflow: hidden;

}

/* Тут у нас картинка сама и т.д. */
.element-card-content {

  position: absolute;

  z-index: 3;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

}

.element-card-content img {
 
  width: 90%;
  height: auto;
  
}

.element-card-conten-changes-img {
 
  width: 60% !important;
  height: auto;
  
}

/* А это обложка задняя, тут костамизация*/
.element-card-cover {
 
  position: absolute;

  z-index: 1;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;


  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start; 

  background: linear-gradient(rgb(0, 58, 0), rgb(128, 108, 0));
  
}

.element-card-title_new {
  text-align: center;
}

.element-card-title_new_needed {
  font-weight: bold;
  color: orangered;
}

.element-card-content_mine {
  background: linear-gradient(rgb(0, 52, 112), rgb(6, 34, 66));
}
.card-mega-cover {

    padding: 0px !important;
    border: none;
    border-radius: 10%;
    display: flex;
    justify-content: center;

}

.card-mega-cover-strike {
   background: linear-gradient(rgb(175, 0, 0), rgb(95, 2, 2));
}

.card-mega-cover-movement {
    background: linear-gradient(rgb(1, 134, 52), rgb(0, 94, 5));
 }

 .card-mega-cover-defence {
    background: linear-gradient(rgb(2, 66, 206), rgb(0, 39, 97));
 }

 .card-mega-cover-tier3 {
    background: linear-gradient(rgb(255, 166, 1), rgb(230, 207, 0));
 }

.card-mega-cover img {
    width: 96%;
    height: auto;
}

.card-mega-content {

    width: 100%;
    height: 100%;
    
}

.card-mega-content-logo-img {

    position: absolute;
    width: 80%;

    top: 10%;
    left: 10%;   


}

.card-mega-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-mega-content-mini-img {
    position: absolute;
    width: 20%;

    top: 83%;
    left: 10%;    
}

.card-mega-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-mega-content-title {

    position: absolute;
    width: 70%;
    text-align: left;

    top: 62%;   
    left: 10%;

    font-size: 1vw;

    font-family: 'CH';
    text-transform: uppercase;


}

.card-mega-content-tier {
    position: absolute;
    width: 80%;
    text-align: right;

    top: 3%;

    font-size: 70%;

    font-family: 'CH';
    text-transform: uppercase;  
}
.card-trefa-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: 1px rgb(68, 2, 2) solid;
}

.card-trefa-cover img {
    width: 100%;
    height: 100%;
}

.card-trefa-content {

    width: 100%;
    height: 100%;
    
}

.card-trefa-content-logo-img {

    position: absolute;
    width: 90%;

    top: 18%;
    left: 10%;   


}

.card-trefa-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-trefa-content-mini-img {
    position: absolute;
    width: 20%;

    top: 83%;
    left: 10%;    
}

.card-trefa-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-trefa-content-title {

    position: absolute;
    width: 90%;
    text-align: left;

    top: 10%;   
    left: 10%;

    font-size: 1vw;

    font-family: 'MyriadPro';
    text-transform: uppercase;

    font-weight: bold;

    -webkit-transform: skewY(-10deg);

            transform: skewY(-10deg);


}

.card-trefa-content-tier {
    position: absolute;
    width: 80%;
    text-align: right;

    top: 81%;

    font-size: 80%;

    font-family: 'MyriadPro';
    text-transform: uppercase;  

    font-weight: bold;

    -webkit-transform: skewY(-4deg);

            transform: skewY(-4deg);

}
.card-points-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-points-cover img {
    width: 100%;
    height: 100%;
}

.card-points-content {

    width: 100%;
    height: 100%;
    
}

.card-points-content-logo-img {

    position: absolute;
    width: 94%;

    top: 0%;
    left: 3%;   


}

.card-points-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-points-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-points-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-points-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 65%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'MyriadPro';
    text-transform: uppercase;

    font-weight: bold;

}

.card-points-content-tier {
    position: absolute;
    width: 80%;
    text-align: right;

    top: 88%;

    font-size: 120%;

    font-family: 'MyriadPro';
    text-transform: uppercase;  

    font-weight: bold;

}
.card-night-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-night-cover img {
    width: 100%;
    height: 100%;
}

.card-night-content {

    width: 100%;
    height: 100%;
    
}

.card-night-content-logo-img {

    position: absolute;
    width: 94%;

    top: 30%;
    left: 3%;   


}

.card-night-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-night-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-night-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-night-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 7%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'Bewlay';
    text-transform: uppercase;

    font-weight: bold;

}

.card-night-content-tier {
    position: absolute;
    width: 80%;
    text-align: right;

    top: 88%;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;

}
.card-clouds-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-clouds-cover img {
    width: 100%;
    height: 100%;
}

.card-clouds-content {

    width: 100%;
    height: 100%;
    
}

.card-clouds-content-logo-img {

    position: absolute;
    width: 90%;

    top: 1%;
    left: 5%;   


}

.card-clouds-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-clouds-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-clouds-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-clouds-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 65%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'Bewlay';
    text-transform: uppercase;

    font-weight: bold;

    color: rgb(250, 197, 117);
    -webkit-filter:drop-shadow(0 0 6px rgb(8, 0, 53));
            filter:drop-shadow(0 0 6px rgb(8, 0, 53))

}

.card-clouds-content-tier {
    position: absolute;
    width: 80%;
    text-align: left;

    top: 85%;

    font-size: 110%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;

}
.card-markin-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-markin-cover img {
    width: 100%;
    height: 100%;
}

.card-markin-content {

    width: 100%;
    height: 100%;
    
}

.card-markin-content-logo-img {

    position: absolute;
    width: 90%;

    top: 40%;
    left: 5%;   


}

.card-markin-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-markin-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-markin-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-markin-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 25%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'Bewlay';
    text-transform: uppercase;

    -webkit-filter: drop-shadow(3px 3px black);

            filter: drop-shadow(3px 3px black);

    font-weight: bold;

    color: white;

}

.card-markin-content-tier {
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 6%;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;

}
.card-brazil-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-brazil-cover img {
    width: 100%;
    height: 100%;
}

.card-brazil-content {

    width: 100%;
    height: 100%;
    
}

.card-brazil-content-logo-img {

    position: absolute;
    width: 80%;

    top: 2%;
    left: 10%;   


}

.card-brazil-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-brazil-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-brazil-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-brazil-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 60%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'Bewlay';
    text-transform: uppercase;

    -webkit-filter: drop-shadow(3px 3px black);

            filter: drop-shadow(3px 3px black);

    font-weight: bold;

    color: white;

}

.card-brazil-content-points {
    position: absolute;
    width: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 6%;
    left: 82%;

    color: black;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;

}

.card-brazil-content-tier {
    position: absolute;
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    top: 80%;
}

.card-brazil-content-tier img {
    height: 80%;
    width: auto;
}
.card-brazil-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-brazil-cover img {
    width: 100%;
    height: 100%;
}

.card-brazil-content {

    width: 100%;
    height: 100%;
    
}

.card-brazil-content-logo-img {

    position: absolute;
    width: 80%;

    top: 2%;
    left: 10%;   


}

.card-brazil-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-brazil-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-brazil-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-brazil-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 60%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'Bewlay';
    text-transform: uppercase;

    -webkit-filter: drop-shadow(3px 3px black);

            filter: drop-shadow(3px 3px black);

    font-weight: bold;

    color: white;

}

.card-brazil-content-points {
    position: absolute;
    width: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 6%;
    left: 82%;

    color: black;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;

}

.card-brazil-content-tier {
    position: absolute;
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    top: 80%;
}

.card-brazil-content-tier img {
    height: 80%;
    width: auto;
}
.card-brazil-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-brazil-cover img {
    width: 100%;
    height: 100%;
}

.card-brazil-content {

    width: 100%;
    height: 100%;
    
}

.card-brazil-content-logo-img {

    position: absolute;
    width: 80%;

    top: 2%;
    left: 10%;   


}

.card-brazil-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-brazil-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-brazil-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-brazil-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 60%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'Bewlay';
    text-transform: uppercase;

    -webkit-filter: drop-shadow(3px 3px black);

            filter: drop-shadow(3px 3px black);

    font-weight: bold;

    color: white;

}

.card-brazil-content-points {
    position: absolute;
    width: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 6%;
    left: 82%;

    color: black;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;

}

.card-brazil-content-tier {
    position: absolute;
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    top: 80%;
}

.card-brazil-content-tier img {
    height: 80%;
    width: auto;
}
.card-samurai-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-samurai-cover img {
    width: 100%;
    height: 100%;
}

.card-samurai-content {

    width: 100%;
    height: 100%;
    
}

.card-samurai-content-logo-img {

    position: absolute;
    width: 90%;

    top: 10%;
    left: 5%;   


}

.card-samurai-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-samurai-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-samurai-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-samurai-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 75%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'Bewlay';
    text-transform: uppercase;

    -webkit-filter: drop-shadow(3px 3px black);

            filter: drop-shadow(3px 3px black);

    font-weight: bold;

    color: white;

}

.card-samurai-content-tier {
    position: absolute;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 6%;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;
    
    -webkit-filter: drop-shadow(1px 1px black);
    
            filter: drop-shadow(1px 1px black);

}
.card-flowers-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-flowers-cover img {
    width: 100%;
    height: 100%;
}

.card-flowers-content {

    width: 100%;
    height: 100%;
    
}

.card-flowers-content-logo-img {

    position: absolute;
    width: 90%;

    top: 7%;
    left: 5%;   


}

.card-flowers-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-flowers-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-flowers-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-flowers-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 75%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'MyriadPro';
    text-transform: uppercase;

    -webkit-filter: drop-shadow(5px 5px rgb(87, 1, 1));

            filter: drop-shadow(5px 5px rgb(87, 1, 1));

    font-weight: bold;

    color: white;

}

.card-flowers-content-tier {
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 6%;

    font-size: 120%;

    font-family: 'MyriadPro';
    text-transform: uppercase;  

    font-weight: bold;

    -webkit-filter: drop-shadow(1px 1px rgb(87, 1, 1));

            filter: drop-shadow(1px 1px rgb(87, 1, 1));

}
.card-kukinhero-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;

    border: 1px solid rgba(128, 128, 128, 0.253);
}

.card-kukinhero-cover img {
    width: 100%;
    height: 100%;
}

.card-kukinhero-cover:hover img {
    -webkit-filter: hue-rotate(-30deg);
            filter: hue-rotate(-30deg);
}

.card-kukinhero-content {

    width: 100%;
    height: 100%;
    
}

.card-kukinhero-content-logo-img {

    position: absolute;
    width: 75%;

    top: 1%;
    left: 20%;
    
    /*filter: invert( 100% ) hue-rotate(180deg);*/


}

.card-kukinhero-content-logo-img img {
    width: 100%;
    height: auto;  
    -webkit-filter: unset !important;  
            filter: unset !important;
}

.card-kukinhero-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-kukinhero-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-kukinhero-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 79%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'PT Sans';
    text-transform: uppercase;

    -webkit-filter: drop-shadow(-3px -3px 0px black) drop-shadow(3px 3px 0px black) drop-shadow(0px 0px 5px black);

            filter: drop-shadow(-3px -3px 0px black) drop-shadow(3px 3px 0px black) drop-shadow(0px 0px 5px black);

    font-weight: bold;

    color: white;

}

.card-kukinhero-content-tier {
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 66%;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;

    -webkit-filter: drop-shadow(-3px -3px 0px black) drop-shadow(3px 3px 0px black) drop-shadow(0px 0px 5px black);

            filter: drop-shadow(-3px -3px 0px black) drop-shadow(3px 3px 0px black) drop-shadow(0px 0px 5px black);

}
.card-ptichka-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-ptichka-cover img {
    width: 100%;
    height: 100%;
}

.card-ptichka-content {

    width: 100%;
    height: 100%;
    
}

.card-ptichka-content-logo-img {

    position: absolute;
    width: 60%;

    top: 20%;
    left: 20%;   


}

.card-ptichka-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-ptichka-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-ptichka-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-ptichka-content-title {

    position: absolute;
    width: 80%;
    text-align: center;

    top: 5%;   
    left: 10%;

    font-size: 0.5vw;

    font-family: 'Arial';

    -webkit-filter: drop-shadow(3px 3px black);

            filter: drop-shadow(3px 3px black);

    font-weight: bold;

    color: white;

}

.card-ptichka-content-tier {
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 6%;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;

}
.card-trefafire-cover {

    padding: 0px !important;
    border: 1px solid rgb(110, 59, 0);
    border-radius: 7%;
    display: flex;
    justify-content: center;
    color: white;
}

.card-trefafire-cover img {
    width: 100%;
    height: 100%;
}

.card-trefafire-content {

    width: 100%;
    height: 100%;
    
}

.card-trefafire-content-logo-img {

    position: absolute;
    width: 70%;

    top: 2%;
    left: 15%;   


}

.card-trefafire-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-trefafire-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-trefafire-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-trefafire-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 60%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'Bewlay';
    text-transform: uppercase;

    -webkit-filter: drop-shadow(3px 3px black);

            filter: drop-shadow(3px 3px black);

    font-weight: bold;
    color: rgb(255, 174, 0);
    opacity: 0.8;

}

.card-trefafire-content-tier {
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 87%;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;
    color: rgb(255, 136, 0);

}
.mountin_container {
    position: relative;
    overflow: visible;
}

.mountin_container svg {
    overflow: visible;
}

.pologonGraph_text1 {
    -webkit-transform: translateX(15px) translateY(-50%);
            transform: translateX(15px) translateY(-50%);
}
.pologonGraph_text2 {
    -webkit-transform: translateX(15px) translateY(-50%);
            transform: translateX(15px) translateY(-50%);
}
.pologonGraph_text3 {
    -webkit-transform: translateX( calc(-100% - 15px) ) translateY(-50%);
            transform: translateX( calc(-100% - 15px) ) translateY(-50%);
}

.pologonGraphQuad_text1{
    -webkit-transform: translateX(0%) translateY(-120%);
            transform: translateX(0%) translateY(-120%);
}
.pologonGraphQuad_text2{
    -webkit-transform: translateX( calc(-100% - 0px) ) translateY(-122%);
            transform: translateX( calc(-100% - 0px) ) translateY(-122%);
}
.pologonGraphQuad_text3{
    -webkit-transform: translateX( calc(-115%) ) translateY(0%);
            transform: translateX( calc(-115%) ) translateY(0%);
}
.pologonGraphQuad_text4{
    -webkit-transform: translateX( 15% ) translateY(0%);
            transform: translateX( 15% ) translateY(0%);
}


.mountin_container_polygon {
    stroke: rgb(27, 187, 187);
    stroke-width: 2px;
    stroke-opacity: 0.6;
}

.mountin_container circle {
    fill: white;
    /* stroke: rgb(0, 39, 54); */
    stroke-width: 2px;
    position: relative;
}

.pologonGraph_content {
    position: absolute;
    width: 200px;
    height: 150px;
    background: red;
}

.mountin_container
line {
    stroke: rgba(87, 102, 102, 0.644);
    stroke-width: 1px;
    stroke-dasharray: 300;
}

.mountin_container
svg
polygon {
    stroke: rgba(87, 102, 102, 0.253);
    stroke-width: 1px;
    stroke-opacity: 0.6;
    fill: rgba(199, 158, 82, 0.178);
}

/* .pologonGraph {
    stroke: rgba(2, 221, 221, 0.87);
    stroke-width: 2px;
    stroke-opacity: 0.9;
    fill: none;
} */

.pologonGraph_line_polygon {
    fill: rgba(6, 45, 97, 0.651) !important;
    /* fill: rgba(6, 46, 97, 0.418); */
}


.pologonGraph_text {
    position: absolute;
    font-family: 'UNI';
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.26);
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 6px;
}

.pologonGraph_text 
svg {
    fill: rgb(0, 35, 58);
    stroke: none;
}
.pologonGraph_text 
svg polygon {
    fill: rgb(0, 35, 58);
    stroke: none;
}

.pologonGraph_text 
.pologonGraph_text_right
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.pologonGraph_text_right
.pologonGraph_text_right_top
{
    font-size: 14px;
    height: 40%;
}

.pologonGraph_text_right
.pologonGraph_text_right_bottom
{
    font-size: 9px;
    height: -webkit-fit-content;
    height: fit-content
}

.mountain_strike
svg {
    fill: red !important;
}

.mountain_flow
svg {
    fill: rgb(21, 35, 114) !important;
}

.mountain_jump
svg {
    fill: rgb(0, 162, 255) !important;
}

.mountain_freeze
svg 
polygon
{
    fill: rgb(87, 35, 16) !important;
}
.geks_container_svg {
    position: relative;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.geks_container_svg svg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: visible !important;
}

.geks_container_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.geks_container_title span {
    color: white;
    font-family: 'Rubik';
    font-size: 12px;
    text-align: center;
    line-height: 14px;
}

.geks_container_title_low {
}

.geks_container_title_low span {
    font-size: 7px;
    text-align: center;
    line-height: 10px;
    margin-top: 5px;
}

.geks_container_svg_icon {
    position: relative;
    
}

.geks_container_svg_icon_gold path, .geks_container_svg_icon_gold polygon {
    fill: url(#grad_gold);
}

.geks_container_svg_icon_silver path, .geks_container_svg_icon_silver polygon {
    fill: url(#grad_silver);
}

.geks_container_svg_icon_bronze path, .geks_container_svg_icon_bronze polygon {
    fill: url(#grad_bronze);
}

.geks_container_svg img {
    position: relative;
    /* filter: invert(100%); */
}

.geks_container_blank {
    stroke: white;
    fill: none;
    stroke-width: 4%;
}

.geks_container_svg_text {
    position: relative;
    color: white;
    font-family: 'Rubik';
    text-align: center;
    font-size: 150%;
}

.geks_container_svg_text_gold {
    color: #580505;
}

.geks_container_svg_text_silver {
    color: #00284e;
}

.geks_container_svg_text_bronze {
    color: #773703;
}

rect { 
}
.quest_full {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: rgb(255, 255, 255);

    padding: 7px 7px;
    grid-row-gap: 8px;
    row-gap: 8px;

    font-family: 'PFD';
    font-size: 18px;

}

.quest_full_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: rgb(0, 57, 83);
    font-family: 'UNI';

    grid-column-gap: 20px;

    -webkit-column-gap: 20px;

            column-gap: 20px;
}

.quest_full_header img {
    width: 100px;
    height: auto;
}

.quest_full h1 {
    margin-top: 20px;
    height: 15px;
    font-size: 24px;
    color: rgb(0, 57, 83);
    font-family: 'UNI';
}

.quest_full_tasks {
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
}

.quest_full_tasks img {
    width: 25px;
    height: auto;
}

.quest_full_footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.quest_full_footer_reward {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.quest_little {
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 7px;
    /* background: rgb(0, 28, 51); */
    /* background: linear-gradient(rgb(0, 29, 46), rgb(1, 43, 68)) */
    background: rgb(255, 255, 255);

    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    margin-left: 0px;

    /* image-rendering: pixelated; */
    /* image-resolution: 3200; */
    image-rendering: optimizeSpeed;

    padding-left: 7px;
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
            column-gap: 8px;

    font-family: 'PFD';
    text-transform: uppercase;
    font-size: 18px;
    cursor: pointer;
    line-height: 19px;

}

.quest_little:hover {
    background: rgb(229, 232, 233);
}

.quest_little img {
    height: 80%;
    width: auto;
}

.quest_little_progress {
    margin-left: auto;
}

.quest_little_allDone {
    -webkit-animation: doneShine 2s;
            animation: doneShine 2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes doneShine {
    from {
        background: rgb(255, 255, 255);
    }
    50% {
        background: green;
    }
    to {
        background: rgb(255, 255, 255);
    }
}

@keyframes doneShine {
    from {
        background: rgb(255, 255, 255);
    }
    50% {
        background: green;
    }
    to {
        background: rgb(255, 255, 255);
    }
}

.quest_little_dashed {
    color: gray;
}
.person_elements_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(255, 255, 255); */
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;

    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    /* padding-top: 10px;
    padding-bottom: 10px; */
    border-radius: 12px;

    grid-column-gap: 5px;

    -webkit-column-gap: 5px;

            column-gap: 5px;

    overflow-y: visible;

}

.person_elements_plashka_upper {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: rgb(212, 211, 151);
}

.person_elements_plashka_upper_notallow {
    background: rgb(224, 224, 224);
}

.main_center_container_mobile 
.person_elements_plashka_upper {
    height: 35px;
}

.person_elements_plashka_middle {

    margin-top: 20px;
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content: space-around;
    align-items: center;
}

.main_center_container_mobile
.person_elements_plashka_middle {
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;
    height: 100%;
    overflow: auto;
}

.person_elements_plashka_downed {
    width: 100%;
    height: 50px;
    background: rgb(5, 56, 80);
    margin-top: 10px;
}

.person_elements_plashka_button_stat {
    height: 90%;
    width: 130px;
    font-family: "MyriadPro";
    color: rgb(61, 66, 71);
    font-weight: bold;

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    border-radius: 10px;
    border: 1px dotted gray;
    background: rgb(196, 196, 196);

    transition-duration: 1s;

    font-size: 19px;
    text-align: center;
    line-height: 20px;
}

.main_center_container_mobile 
.person_elements_plashka_button_stat {
    width: 70px;
    font-size: 8px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.178);
}

.main_center_container_mobile
.global_info_text {
    /* font-size: 10px !important; */
    width: 20px;
    max-width: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_elements_plashka_button_stat:hover {
    background: white;
    background: rgb(5, 56, 80);
    color: white;
    cursor: pointer;
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
}

.personElementsModalClass {
    width: 1000px !important;
}
.personElementsModalClass
.ant-modal-header {
    height: 0px;
    max-height: 0px;
    padding: 0px;
    visibility: hidden;
}
.personElementsModalClass
.ant-modal-content {
    border-radius: 15px;
    background: radial-gradient(#306271, #0A2837);
}

.personElementsModalClassMobile {
    width: 1000px !important;
}
.personElementsModalClassMobile
.ant-modal-header {
    height: 0px;
    max-height: 0px;
    padding: 0px;
    visibility: hidden;
}

.personElementsModalClassMobile
.ant-modal-content {
    border-radius: 15px;
    background: radial-gradient(#306271, #0A2837);
}

.personElementsModalClassMobile
.ant-modal-body {
    padding: 10px !important;
}

/* ELEMENTS MODAL */

.person_elements_modal_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;

    grid-row-gap: 4px;

    row-gap: 4px;

    overflow-x: hidden;
}

.person_elements_modal_container_mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.person_elements_modal_container_left {
    width: 350px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.personElementsModalClassMobile 
.person_elements_modal_container_left {
    width: 95%;
}

.person_elements_modal_container_right {
    height: 650px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

/* DEVIDER */

.person_elements_modal_container_devider {
    color: white;
    font-family: 'Rubik';
    font-weight: 100 !important;
    font-size: 15px;
    width: 100%;
    height: 36px;
    min-height: 36px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.person_elements_modal_container_devider_crossed_left {
    height: 1px;
    background: white;
    width: 100%;
}
.person_elements_modal_container_devider_crossed_right {
    height: 1px;
    background: white;
    width: 100%;
}
.person_elements_modal_container_devider_crossed_center {
    height: -webkit-fit-content;
    height: fit-content;
    text-align: center;
    width: -webkit-fit-content;
    width: fit-content;
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 15px;
}


.person_elements_modal_container_elementslist {

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    grid-row-gap: 3px;

    row-gap: 3px;

}

.person_elements_modal_container_mobile 
.person_elements_modal_container_elementslist {
    grid-row-gap: 1px;
    row-gap: 1px;
}

.person_elements_modal_container_onerow {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: wheat;
}

.person_elements_modal_container_mobile 
.person_elements_modal_container_onerow {
    height: 40px;
}

.person_elements_modal_container_img {
    width: 50px;
    height: 50px;
    background: rgb(5, 56, 80);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_elements_modal_container_mobile
.person_elements_modal_container_img {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
}

.person_elements_modal_container_img_notgood {
    background: rgb(65, 34, 34);
}

.person_elements_modal_container_img_notused {
    background: rgb(110, 110, 110);
    background: rgb(64, 80, 94);
}

.person_elements_modal_container_img_notadded {
    background: rgb(40, 48, 54);
}

.person_elements_modal_container_img img {
    width: 90%;
    height: auto;
}

.person_elements_modal_container_title {
    width: 130px;
    font-size: 20px;
    font-family: 'MyriadPro';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_elements_modal_container_title a {
    color: white !important;
}

.person_elements_modal_container_mobile
.person_elements_modal_container_title {
    width: 50px;
    min-width: 50px;
    font-size: 10px;
    line-height: 11px;
}

.person_elements_modal_container_title span {
    max-width: 80%;
    text-align: center;
    line-height: 14px;
}


.person_elements_modal_container_mobile
.person_elements_modal_container_title span {
    max-width: 80%;
    text-align: center;
    line-height: 11px;
}

.person_elements_modal_container_usedtime {
    width: 50px;
    font-family: 'UNI';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_elements_modal_container_mobile
.person_elements_modal_container_usedtime {

    width: 35px;
    max-width: 35px;
    font-size: 12px;

}

.person_elements_modal_container_addelement {
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'MyriadPro';
    cursor: pointer;
}

.person_elements_modal_container_mobile
.person_elements_modal_container_addelement {
    width: 150px;
    max-width: 150px;

}

.person_elements_modal_container_addelement span {
    background: #9fcedb;
    width: 90%;
    border-radius: 2px;
    text-align: center;
    color: #0A2837;
    transition-duration: 0.7s;
}

.person_elements_modal_container_addelement span:hover {
    background: #6e9eac;
}

.person_elements_modal_container_usedtime_less08 {
    font-weight: bold;
    color:rgb(255, 66, 66);
}

.person_elements_modal_container_usedtime_08 {
    font-weight: bold;
    color:rgb(255, 127, 127);
}

.person_elements_modal_container_usedtime_09 {
    color:rgb(255, 174, 174);
}

.person_elements_modal_container_usedtime_10 {
    font-weight:normal;
    color: wheat;
}

.person_elements_modal_container_usedtime_11 {
    font-weight: bold;
    color:rgb(137, 229, 194);
}

.person_elements_modal_container_usedtime_12 {
    font-weight: bold;
    color:rgb(88, 168, 63);
}

.person_elements_modal_container_usedtime_13 span {
    color:white;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: linear-gradient(rgb(248, 150, 37), #855729);
    border-radius: 4px;
}

.person_elements_modal_container_usedtime_14 span {
    color:white;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: linear-gradient( rgb(128, 199, 195), rgb(22, 46, 70) );
    border-radius: 4px;
}

.person_elements_modal_container_usedtime_15 span {
    color:white;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: linear-gradient(rgb(255, 136, 0), gold);
    border-radius: 4px;
}

.person_elements_modal_container_options {
    
    height: 25px;
    width: 70px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    font-weight: bold;
    color: black;

    background: rgb(248, 248, 248);

    grid-column-gap: 3px;

    -webkit-column-gap: 3px;

            column-gap: 3px;
    padding-left: 7px;

    border-radius: 6px;
    border: 1px solid gray;
}

.person_elements_modal_container_tier_1 {
    color:rgb(161, 139, 12);
}
.person_elements_modal_container_tier_2 {
    color:rgb(0, 81, 255);
}
.person_elements_modal_container_tier_3 {
    color:rgb(141, 8, 8);
}

.person_elements_modal_container_options_strike
svg {
    fill: red !important;
}

.person_elements_modal_container_options_flow
svg {
    fill: rgb(21, 35, 114) !important;
}

.person_elements_modal_container_options_jump
svg {
    fill: rgb(0, 162, 255) !important;
}

.person_elements_modal_container_options_freeze
svg {
    fill: rgb(83, 36, 19) !important;
}

/* HEADER */

.person_elements_modal_container_header 
.person_elements_modal_container_img
{
    background: none;
}

.person_elements_modal_container_header span
{
    font-size: 12px !important;
    border: none !important;
    font-family: 'MyriadPro' !important;
    font-weight: bold !important;
    color: wheat;
}

.person_elements_modal_container_mobile
.person_elements_modal_container_header span
{
    font-size: 10px !important;
    text-align: center;
}

.person_elements_modal_container_header 
.person_elements_modal_container_options {
    border: none;
    background: none;
    font-family: 'MyriadPro';
}

#pemcor_header 
.person_elements_modal_container_usedtime {
    cursor: pointer;
}

#pemcor_header 
.person_elements_modal_container_usedtime_choosed
span
{
    color: wheat;
    color: #53BAE2;
}

/* SEQUENCES */
.person_elements_modal_container_sequences {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.person_elements_modal_container_sequence {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
}

.person_elements_modal_container_perfKoef {
    width: 40px;
    height: -webkit-fit-content;
    height: fit-content;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}

.person_elements_modal_container_icon {
    cursor: pointer;
    transition-duration: 0.3s;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    font-size: 20px;
}

.person_elements_modal_container_icon_checked {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.person_elements_modal_container_row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

/* CARD */

.person_elements_modal_container_quest_container {
     width: 320px;
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: flex-start;
     grid-column-gap: 10px;
     -webkit-column-gap: 10px;
             column-gap: 10px;
     height: 150px;
}

.person_elements_modal_container_quest_container_icon {
    width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.person_elements_modal_container_quest_container_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 190px;
}

.person_elements_modal_container_quest_container_title {
    font-family: 'UNI';
    color: white;
    font-size: 18px;
    height: 23px;
}

.person_elements_modal_container_quest_container_text {
    font-family: 'MyriadPro';
    color: white;
    font-size: 16px;
    width: 90%;
    height: 60px;
    line-height: 17px;
}

.person_elements_modal_container_quest_container_rest {
    font-family: 'Rubik';
    color: white;
    font-size: 10px;
    width: 100%;
    height: 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.person_elements_modal_container_quest_container_progress {
    width: 100%;
    height: 4px;
    background: rgb(19, 39, 77);
}

.person_elements_modal_container_quest_container_progress_inside {
    height: 4px;
    background: #53BAE2;
}

.person_elements_modal_container_row_cover {
    position: relative;
    width: 100%;
    height: 300px;
    min-height: 300px;
}

/* Это на страничке которая*/
.person_elements_plashka_upper
.person_elements_modal_container_row_cover {
    width: 40px;
    height: 30px;
    min-height: 30px;
}

.main_center_container_mobile
.person_elements_plashka_upper
.person_elements_modal_container_row_cover {
    width: 40px;
    height: 20px;
    min-height: 20px;
}

.person_elements_modal_container_row_cover > div {
    position: absolute;
    top: 15%;
    left: 26%;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
}


.person_elements_plashka_upper
.person_elements_modal_container_row_cover > div {
    position: absolute;
    top: -35%;
    left: 50%;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
}

.person_elements_modal_container_row_cover> div:nth-child(1) {
    -webkit-transform: rotate(20deg) translateX(20%);
            transform: rotate(20deg) translateX(20%);
}
.person_elements_modal_container_row_cover> div:nth-child(2) {
    -webkit-transform: rotate(-12deg) translateX(-20%);
            transform: rotate(-12deg) translateX(-20%);
}
.person_elements_modal_container_row_cover> div:nth-child(3) {
    -webkit-transform:  scale(1.07) translateY(-5%) rotate(5deg);
            transform:  scale(1.07) translateY(-5%) rotate(5deg);
}

.person_elements_modal_container_row_elements_add {
    cursor: pointer;
    height: 60px;
    min-height: 60px;
    background: #92b9c5;
    margin-top: 10px;
    font-family: 'MyriadPro';
    color: white;
    font-size: 18px;
    transition-duration: 1s;
}

.person_elements_modal_container_row_elements_add a {
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_elements_modal_container_row_elements_add:hover {
    background: #07313d;
}
.person_nodata_plashka {
    width: 100%;
    height: 100%;
    background: rgb(253, 253, 253);
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );

            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    position: relative;
    overflow: hidden;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;

    border-radius: 10px;
    border: 1px solid rgb(255, 187, 0);

}

.person_nodata_plashka > img {
    width: 40px;
    height: 40px;
}

.person_nodata_plashka > div {
    width: 60%;
    height: -webkit-fit-content;
    height: fit-content;
    font-family: 'PFD';
}

.person_nodata_plashka_attentions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.person_nodata_plashka_attentions > b {
    color: rgb(243, 97, 0);
}

.person_nodata_plashka_attentions > span {
    color:rgb(5, 56, 80);
    cursor: pointer;
}

.person_nodata_plashka_attentions > span:hover {
    color:rgb(5, 90, 129);
}
.person_quests_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(255, 255, 255); */
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;
    
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;   

    grid-row-gap: 5px;   

    row-gap: 5px;

    overflow-y: visible;

}

.person_quests_plashka_inner {
    display: flex;
    flex-direction: column;
    height: -webkit-fit-content;
    height: fit-content;
    grid-row-gap: 5px;
    row-gap: 5px;
    width: 100%;
}
.fight_modal_container__categoryName {
    font-family: 'UNI';
    text-align: center;
    font-size: 14px;
    margin-bottom: 4px;
}


.fight_modal_container_mobile 
.fight_modal_container__categoryName {
    font-size: 9px;
    margin-bottom: 7px;
    margin-top: 3px;
}

.fight_modal_container__competition {
    margin-top: 12px;
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.301);
    border-radius: 4px;
}

.fight_modal_container__personRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.fight_modal_container__personRow_person {
    width: 200px;
    max-width: 200px;
}


.fight_modal_container_mobile 
.fight_modal_container__personRow_person {
    width: 170px;
    max-width: 170px;
}


.fight_modal_container__personRow_cell {
    width: 70px;
    max-width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'UNI';
    font-size: 17px;
}

.fight_modal_container_mobile 
.fight_modal_container__personRow_cell {
    width: 50px;
    max-width: 50px;
    font-size: 12px;
}

.fight_modal_container__personRow_cell span {
    font-family: 'PFD';
    font-size: 13px;
    font-weight: bold;
}


.fight_modal_container_mobile .fight_modal_container__personRow_cell span {
    font-size: 9px;
} 

.fight_modal_container__personRow_cell_win {
    color: rgb(0, 77, 0);
}

.fight_modal_container__personRow_loose {
    color: rgb(131, 26, 0);
}

.fight_modal_container__personRow_cell_video {
    width: 50px;
    max-width: 50px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid gray;
    border-radius: 4px;
    cursor: pointer;
    transition-duration: 0.6s;
}


.fight_modal_container_mobile 
.fight_modal_container__personRow_cell_video {
    width: 30px;
    max-width: 30px;  
    height: 20px;
}


.fight_modal_container__personRow_cell_video:hover {
    background: rgb(153, 175, 185);
}
.person_fights_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    display: flex;
    flex-direction: row;

    justify-content: space-evenly;
    align-items: center;
    
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;   

    grid-row-gap: 15px;   

    row-gap: 15px;

    overflow-y: auto;
    cursor: pointer;

}
.person_enemys_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;
    
    padding-top: 10px;
    padding-bottom: 10px; 

    overflow: visible;

}

.person_enemys_plashka_one {
    width: 100%;
    cursor: pointer;
    transition-duration: 0.7s;
}
.person_enemys_plashka_one:hover {
    background: rgb(206, 215, 226);
}

.person_enemys_plashka_one_suffix {
    width: 120px;
    font-size: 15px;
    font-family: 'PFD';
    font-weight: bold;
    margin-left: 10px;
}

.person_enemys_plashka_one_suffix_even {
    color: rgb(0, 57, 83);
}

.person_enemys_plashka_one_suffix_win {
    color: rgb(18, 63, 0);
}

.person_enemys_plashka_one_suffix_lose {
    color: rgb(83, 26, 0);
}

.person_enemys_plashka
.person_little {
    background: none;
}

.person_enemys_plashka_bycompetitions {
    width: 100%;
    text-align: center;
    font-family: 'PFD';
    cursor: pointer;
}
.achivment_tooltip_container {
    width: 230px;
    height: -webkit-fit-content;
    height: fit-content;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 6px;
    grid-row-gap: 3px;
    row-gap: 3px;
    background: linear-gradient(white, rgb(219, 219, 219));
    border-radius: 10px;
}

.achivment_tooltip_container img {
    width: 210px;
    height: 210px;
}


.achivment_tooltip_container h2 {
    width: 100%;
    text-align: center;
    font-family: 'DIN';
    font-size: 30px;
    color: rgb(0, 36, 56);
    padding: none !important;
    margin: none !important;
    margin-bottom: 0px !important;
    line-height: 30px;
}

.achivment_tooltip_container_text {
    font-family: 'PFD';
    font-size: 16px;
    text-align: center;
    width: 90%;
    color: rgb(0, 36, 56);
}

.achivment_tooltip_container_param {
    width: 70%;
    text-align: left;
    font-family: 'DIN';
    font-size: 15px;
    color: rgb(0, 36, 56);
}

/* LITTLE */

.little_achivments_plashka_achiv {
    /* width: 110px;
    height: 110px; */
    image-rendering: optimizeQuality;
    position: relative;
}

.little_achivments_plashka_achiv > img {
    width: 100%;
    height: 100%;
}

.little_achivments_plashka_achiv_bar {
    position: absolute;
    /* left: 20px;
    top: 110px;
    width: 70px;
    height: 6px; */

    border: 1px solid rgb(189, 200, 202);
    background: linear-gradient( rgb(201, 208, 211), rgb(235, 238, 238) );
    border-radius: 2px;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.little_achivments_plashka_achiv_bar_inner {
    height: 100%;
    width: 0px;
    background: linear-gradient(goldenrod, rgb(255, 174, 0))
}

/* HELPER */

.condition_text_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.condition_text_container_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
}

.condition_text_container_items {
    display: flex;
    flex-direction: row;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
}
.article_page_main {
    width: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: row;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 20px;
    padding-top: 0px;

    position: relative;
    justify-content: space-between;
}

.main_center_container_mobile .article_page_main {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    padding-top: 0px;

    justify-content: flex-start;
    align-items: center;
}

.article_page_main_left {
    width: 750px;
    display: flex;
    flex-direction: column;
}

.article_page_main_right {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding-top: 30px;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.article_page_main_right_articles {
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid rgb(206, 205, 205);
    border-radius: 10px;
    overflow: hidden;
}

.article_page_main_right_article_title {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;

    background: #053850;
    color: white;
    font-family: 'DIN';
    font-size: 18px;
}

.article_page_main_right_article {
    width: 90%;
    border-bottom: 1px solid rgb(192, 212, 218);
    margin-top: 4px;
    margin-bottom: 4px;
}

.article_page_main h1 {
    width: 100%;
    font-family: 'DIN';
    font-size: 60px;
    color: #053850;
    margin-bottom: 0px;
    height: 75px;
}

.main_center_container_mobile .article_page_main h1 {
    width: 100%;
    font-family: 'DIN';
    font-size: 50px;
    color: #053850;
    margin-bottom: 0px;
    line-height: 50px;
    height: -webkit-fit-content;
    height: fit-content
}

.article_page_main_discription {
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;

    font-family: 'MyriadPro';
    color: gray;
    font-size: 20px;
}

.article_page_main_text {
    margin-top: 10px
}

.article_page_main_right_article:last-child {
    border-bottom: none;
}

.article_little_container {
    padding-top: 5px;
    padding-bottom: 5px;
}

.article_little_container a {
    display: flex;
    flex-direction: row;
    grid-column-gap: 4px;
    -webkit-column-gap: 4px;
            column-gap: 4px;
    justify-content: flex-start;
    align-items: center;
}

.article_little_container a span {
    -webkit-text-decoration: dotted;
            text-decoration: dotted;
}
.person_achivments_plashka {
    width: 100%;
    min-height: 70px;;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;
    
    flex-wrap: wrap;

    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;   

    grid-column-gap: 1px;   

    -webkit-column-gap: 1px;   

            column-gap: 1px;

    overflow-y: auto;
    height: -webkit-fit-content;
    height: fit-content;
}
.person_page {
    height: -webkit-fit-content;
    height: fit-content;
    min-height: 600px;
    padding-bottom: 30px;
}

.aaa {
    width: max(1100px, 90vw) !important;
}

.person_page_inventar_modal {
    width: max(700px, 60vw) !important;
}

.aaa .ant-modal-header {
    background: rgb(5, 56, 80);
}

.aaa .ant-modal-close-x {
    color: white;
}

.aaa .ant-modal-title {
    color: white;
    font-family: 'PFD';
    text-transform: uppercase;
}

.person_page_inventar_modal_mobile .ant-modal-body {
    padding-left: 0px;
    padding-right: 0px;
}

.person_page_inventar_modal_mobile {
}
.person_place_rect_mini {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: linear-gradient( rgba(255, 255, 255, 0.651), rgba(255, 255, 255, 0.534));

    border-radius: 8%;
    background-position: center;
    position: relative;
    /* background-image: image(silver); */
    /* background-image: url('/public/images/ver2/textures/gold.jpg'); */
}

.person_place_rect_mini_right {
    position: absolute;
    left: 55%;
    top: 5%;
    background: rgba(255, 255, 255, 0.795);
    padding: 4px;
    border: 1px solid gray;
    border-radius: 12px;
}

.person_place_rect_mini_upper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.person_place_rect_mini_downed {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;   

    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: 600;

    font-size: 160%;
}

.main_center_container_mobile 
.person_place_rect_mini_downed {
    font-size: 70%;
}

.person_place_rect_mini_downed span {
    color: rgb(5, 56, 80);
    height: 25%;
    width: 99%;
    text-align: center !important;
}

.person_place_rect_mini_downed_data {
    width: 80%;
    font-size: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;   
    margin-bottom: 3px;
}
.school_place_rect_mini {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: linear-gradient( rgba(255, 255, 255, 0.651), rgba(255, 255, 255, 0.534));

    border-radius: 15px;
    background-position: center;
    /* background-image: image(silver); */
    /* background-image: url('/public/images/ver2/textures/gold.jpg'); */
}

.school_place_rect_mini_upper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.school_place_rect_mini_downed {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;   

    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: 700;

    font-size: 180%;
}

.main_center_container_mobile 
.school_place_rect_mini_downed {
    font-size: 70%;
}

.school_place_rect_mini_downed span {
    color: rgb(5, 56, 80);
    height: 25%;
    text-align: center;
    line-height: 27px;
}

.school_place_rect_mini_downed_data {
    width: 80%;
    font-size: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;   
    margin-bottom: 3px;
}
.raiting_big_plashka{
    width: 100%;
    height: 100%;
    padding-top: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    font-family: 'MyriadProUsual';

    /* background:  white;
    padding: 20px;
    border-radius: 5px; */

    overflow-y: auto;
    overflow-x: hidden;


}

.main_center_container_mobile
.raiting_big_plashka {
    padding: 0px;
}


.raiting_big_plashka__best { 
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    padding: 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
    background: rgb(5, 56, 80);
    background: linear-gradient(rgb(0, 16, 26), rgb(5, 56, 80));

}

.raiting_big_plashka__oneraiting {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 55px;
    min-height: 55px;
    
    /* background: rgb(243, 243, 243);
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */

    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(207, 207, 207);

    transition-duration: 0.5s;
    font-family: 'PFD';
    font-weight: 100;
    text-transform: uppercase;

}

.raiting_big_plashka__oneraiting_row {
   -webkit-animation: 0.7s one_row_anim forwards ease-in-out;
           animation: 0.7s one_row_anim forwards ease-in-out;
}

@-webkit-keyframes one_row_anim {
    from {
        margin-left: 30px;
        opacity: 0;
    }
    to {
        margin-left: 0px;
        opacity: 1;
    }
}

@keyframes one_row_anim {
    from {
        margin-left: 30px;
        opacity: 0;
    }
    to {
        margin-left: 0px;
        opacity: 1;
    }
}


.raiting_big_plashka__oneraiting_row_selected,
.raiting_big_plashka__oneraiting_row:hover {
    background: white;
}



.raiting_big_plashka__oneraiting_header {
    margin-top: 15px;
    height: 30px;
    min-height: 30px;
    font-family: 'Uni';
    border-bottom: 1px solid rgb(110, 110, 110);
}

.raiting_big_plashka__oneraiting_place {
    width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.raiting_big_plashka__oneraiting_header
.raiting_big_plashka__oneraiting_place {
    justify-content: flex-start;

    padding-left: 10px;
}

.raiting_big_plashka__oneraiting_place_inside {
    width: 45px;
}

.main_center_container_mobile 
.raiting_big_plashka__oneraiting_place_inside {
    width: 25px;
}

.raiting_big_plashka__oneraiting_category {
    width: 100%;
    padding-left: 20px;
}

.raiting_big_plashka__oneraiting_scores {
    width: 100px;
}

.raiting_big_plashka__raitingtitle {
    font-family: 'PFD';
    font-size: 20px;
    text-align: center;
    margin-bottom: 5px;
}

.main_center_container_mobile 
.raiting_big_plashka__raitingtitle {
    font-size: 14px;
}
.raiting_page {
    padding-top: 20px;
    position: relative;
}
.big_group_plashka {
    width: 100%;
    height: 100%;
    background: rgb(253, 253, 253);
    
    display: flex;
    flex-direction: row;

    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );

            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    position: relative;
    overflow: hidden;

    border-top-left-radius: 80px;
    border-bottom-right-radius: 40px;

}

.main_center_container_mobile .big_group_plashka {
    
    border-top-left-radius: 40px;
    border-bottom-right-radius: 20px;
}


.big_group_plashka___avatar_back {
    position: absolute;
    opacity: 0;
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
    left: -30%;
    -webkit-animation: 'school_avatar_back' 1s;
            animation: 'school_avatar_back' 1s;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes school_avatar_back {
    from {
        left: -30%;
        opacity: 0;
    }
    1% {
        opacity: 0;
    }
    to {
        left: -3%;
        opacity: 0.2;
    }
}

@keyframes school_avatar_back {
    from {
        left: -30%;
        opacity: 0;
    }
    1% {
        opacity: 0;
    }
    to {
        left: -3%;
        opacity: 0.2;
    }
}

.big_group_plashka___avatar_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  
}

.big_group_plashka___info_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    position: relative; 
    padding-left: 50px;
    padding-top: 20px;
}

.main_center_container_mobile .big_group_plashka___info_container {
    padding-left: 15px;
    padding-top: 10px;
}

.big_group_plashka___info_container___name {
    text-align: left; 
    width: 100%;
    /* font-size: 47px; */
    font-family: 'MyriadPro';
}

.main_center_container_mobile .big_group_plashka___info_container___name {
    font-size: 25px !important;
    height: 30px;
}

.big_group_plashka___info_container___info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  
    width: 100%;
    font-size: 22px;
    font-family: 'MyriadPro';
}

.big_group_plashka___info_container___school_group_cordao {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  
    padding-left: 20px;
    width: 100%;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.main_center_container_mobile .big_group_plashka___info_container___school_group_cordao {
    grid-column-gap: 6px;
    -webkit-column-gap: 6px;
            column-gap: 6px;
}

.big_group_plashka___info_container___school_group_cordao 
.rectAvatar {
    /* margin-right: 40px; */
}

.big_group_plashka_medals {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    grid-column-gap: 5px;

    -webkit-column-gap: 5px;

            column-gap: 5px;
}


.main_center_container_mobile 
.big_group_plashka__region {
    margin-left: auto;
    margin-right: 20px;
}

.big_group_plashka__region
.numberText_upper_text {
    font-size: 20px !important;
}

.main_center_container_mobile 
.big_group_plashka__region
.numberText_upper_text {
    font-size: 12px !important;
}
.person_data_change {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
    /* font-family: 'Uni'; */
    color: rgb(5, 56, 80);

    
    font-family: 'PFD';

}

.person_data_change_tabs .ant-tabs-tab {
    color: rgb(5, 56, 80);
    text-transform: uppercase;
}

.person_data_change_tabs .ant-tabs-tab-active {
    color: #1890ff;
}

.person_data_change_tabs .ant-tabs-left-content {
    color: black;
}

.person_data_change_recovery {
    text-transform: none;
    font-family: 'MyriadPro';
    font-size: 18px;
    color: black;
}
.group_structure_plashka {
    width: 100%;
    height: 100%;
    /* background: rgb(253, 253, 253); */

    display: flex;
    flex-direction: row;

    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    position: relative;
    overflow: hidden;
    /* overflow-x: hidden; */
    /* overflow-y: auto; */
}

.group_structure_plashka .persons_little_plashka {
    overflow-y: hidden;
}

.group_structure_plashka_modal {
    width: max(1100px, 90vw) !important;
}

.group_structure_plashka_modal .ant-modal-header {
    background: rgb(5, 56, 80);
}

.group_structure_plashka_modal .ant-modal-close-x {
    color: white;
}

.group_structure_plashka_modal .ant-modal-title {
    color: white;
    font-family: 'PFD';
    text-transform: uppercase;
}

.group_structure_plashka_modal .ant-modal-body {
    background: white;
    font-family: 'PFD';
    text-transform: uppercase;
}

.event_participation_decline_modal .ant-btn-primary {
    background: rgb(182, 0, 0);
    border: rgb(182, 0, 0);
}

.group_structure_plashka_filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
    padding-left: 10px;
    padding-bottom: 10px;
}

.group_structure_plashka_filter_one {
    cursor: pointer;
    font-family: 'PFD';
    font-size: 16px;
    color: gray;
}

.group_structure_plashka_filter_one_selected {
    font-weight: bold;
    color: rgb(5, 56, 80);
}

.group_structure_plashka_inner {
    height: -webkit-fit-content;
    height: fit-content;
    width: 100%;
}

.group_structure_plashka_students {
    width: 100% !important;
}
.calendar_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(243, 243, 243); */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */

    -webkit-user-select: none;

            user-select: none;
    /* border-radius: 5px; */
    /* border: 1px solid rgb(197, 197, 197); */
}

.calendar_header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
}

.calendar_header_item {
    width: 30%;
    text-align: center;
    font-family: 'MyriadPro';
    font-size: 16px;
    cursor: pointer;
    color: rgb(37, 37, 37);
    transition-duration: 0.5s;
}

.calendar_header_item:hover {
    font-size: 17px;
}

.calendar_header_item___selected {
    font-weight: bold;
    font-size: 17px;
    color: rgb(5, 56, 80);
}


.calendar_plashka_outerheader {
    width: 100%;
    height: 40px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: 'PFD';
    font-size: 26px;
    color: rgb(5, 56, 80);

    grid-column-gap: 5px;

    -webkit-column-gap: 5px;

            column-gap: 5px;
}





.calendar_days_header {    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
}

.calendar_days_header_item {
    width: 13%;
    min-width: 13%;
    text-align: center;
    font-family: 'MyriadPro';
    font-size: 16px;
    color: rgb(0, 56, 94); 
}

.calendar_body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;

    grid-row-gap: 2px;

    row-gap: 2px;
    grid-column-gap: 2px;
    -webkit-column-gap: 2px;
            column-gap: 2px
}

/* Это у нас собственно плашечка */
.calendar_body_oneday {
    width: 13%;
    min-width: 13%;
    text-align: center;
    /* border: 1px solid rgb(212, 212, 212); */
    height: 27px;
    font-family: 'Uni';

    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.calendar_body_oneday > span {
    padding-right: 7px;
    padding-bottom: 3px;
    font-size: 12px;
}

.calendar_mini_events {
    position: absolute;
    width: -webkit-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    /* width: 80px; */

    /* background: rgb(223, 223, 223); */
    /* border-radius: 3px; */

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    top: -12px;
    left: -7px;

    overflow: visible;
}

.calendar_body_oneday__blank {
    /* border: 1px solid rgb(233, 233, 233); */
    border: none;
}

.calendar_body_oneday__today {
    color: rgb(5, 56, 80);
    border: 2px solid #EF7F1A;
    /* background: #EF7F1A; */
    border-radius: 10px;
}

.calendar_body_oneday__past {
    color: rgb(187, 187, 187);
}

.calendar_body_oneday__event {
    background: rgb(185, 227, 255); 
    /* color: white; */
    border-radius: 10px;
    color: rgb(5, 56, 80);
}
.calendar_body_oneday__event_championship {
    background: rgb(255, 203, 160); 

}

/* .calendar_body_oneday__event span {
    background: rgb(0, 56, 94); 
    color: white;
    border-radius: 30px;
} */

.ant-popover-inner-content {
    padding: 0px;
    margin: 0px;
    width: -webkit-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: fit-content;
}

.calendar-popover-content {
    min-width: 250px;
}
.results_mini_component {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    /* background: linear-gradient( rgba(255, 255, 255, 0.651), rgba(255, 255, 255, 0.534)); */

    border-radius: 4px;
    background-position: center;
    border-bottom: 1px solid rgb(197, 194, 179);
    /* background-image: image(silver); */
    /* background-image: url('/public/images/ver2/textures/gold.jpg'); */
}

.results_mini_component_event {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    margin-left: 5px;
    cursor: pointer;
}

.results_mini_component_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;   
    width: 100%;
}

.results_mini_component_right_eventtitle {
    font-family: 'MyriadPro';
    font-size: 16px;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.results_mini_component_right_downed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;  
    width: 100%;
    padding-left: 10px;
    padding-right: 12px;
}

.results_mini_component_right_downed > p {
    font-family: 'PFD';
    font-size: 16px;
    height: -webkit-fit-content;
    height: fit-content;
    color: rgb(5, 56, 80);
    margin: 0px;
    padding: 0px;
}

.group_big_plate_medals_inside {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 

    grid-column-gap: 5px; 

    -webkit-column-gap: 5px; 

            column-gap: 5px;
}


.group_results_plashka {
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-content: flex-start;

    position: relative;

    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    border-radius: 10px; */
    
    grid-row-gap: 5px;
    
    row-gap: 5px;

}

.group_results_plashka_season {
    margin-top: 10px;
    font-family: 'UNI';
    color: rgb(0, 57, 83);
}
.shadule-comp-container {
    width: 100%;
    /* height: fit-content; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shadule-comp-main {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.shadule-comp-left-container {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;

    padding-top: 10px;
    padding-bottom: 10px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    grid-row-gap: 5px;

    row-gap: 5px;

}

.shadule-comp-left-container-header {
    width: 270px;
    height: -webkit-fit-content;
    height: fit-content;

    background: white;

    margin-left: 20px;
    margin-bottom: 10px;

    border-radius: 4px;
    overflow: hidden;

    cursor: pointer;
}


.shadule-comp-right-container {
    position: relative;
    display: column;
}

.shadule-comp-right-container-header {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    position: relative;
}

.shadule-comp-right-container-shadule {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.shadule-comp-right-container-header-day {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    border-left: 1px solid rgb(0, 27, 44);
    border-right: 1px solid rgb(0, 27, 44);

    background: rgb(3, 67, 97);

}

.shadule-comp-right-container-header-day p {
    width: -webkit-fit-content;
    width: fit-content;
    height: 10px;
    font-family: 'Uni';
    color: white;

    font-size: 20px;

}

.shadule-comp-right-container-hours {
  position: relative;
  width: 40px;
  min-width: 40px;
  height: 100%;
}

.shadule-comp-right-container-hours-hour {

    position: absolute;
    left: 0px;

    color: rgb(3, 67, 97);
    font-family: 'MyriadPro';
    font-size: 15px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

}



.shadule-comp-right-container-table {
    position: relative;
    width: 100%;
    height: 100%;
}

.shadule-comp-right-container-table-row {
    position: absolute;
    left: 0px;
    top: 0px;
    border: 1px solid rgba(128, 128, 128, 0.486);
}

.shadule-comp-right-container-table-column {
    position: absolute;
    left: 0px;
    top: 0px;
    border: 1px solid rgba(128, 128, 128, 0.486);
}

.shadule-comp-right-container-table-supgroup {
    position: absolute;
    left: 0px;
    top: 0px;  

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid rgb(218, 217, 217);
    border-radius: 3px;

    text-align: center;
    line-height: 5px;

    grid-row-gap: 8px;

    row-gap: 8px;

}

.shadule-comp-right-container-table-supgroup p {

    /* color: black;  
    font-family: 'MyriadPro';
    height: 5px;
    width: fit-content;
    font-size: 18px; */

    color: black;
    font-family: 'MyriadPro';
    /* height: 6px; */
    /* width: -webkit-fit-content; */
    width: -webkit-fit-content;
    width: fit-content;
    font-size: 18px;
    line-height: 14px;
    /* margin-bottom: 0px; */
    height: 14px;
    margin: 0px;
}

.shadule-comp-right-container-table-supgroup
.___center-tire {
    height: 9px;
    line-height: 5px;
}

.shadule-comp-right-container-table-supgroup
.___title_block {
    width: 100%;
    white-space: nowrap;
    overflow-y: visible;
    overflow-x: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
}

.shadule-comp-right-container-table-supgroup
.___title_block p {
    max-width: 90%;
    white-space: nowrap;
    overflow-y: visible !important;
    overflow-x: hidden;
    height: 20px;
    padding-top: 3px;
    text-overflow: ellipsis;
}


.main_center_container_mobile
.shadule-comp-right-container-table-supgroup
.___title_block p {
    font-size: 10px;
}

.shadule-comp-right-container-table-supgroup
.___time_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sh_subgroup-container {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(219, 219, 219);
    font-family: 'MyriadPro';
    color: rgb(0, 56, 80);
    background: rgb(231, 230, 224);
}

.sh_subgroup-container-marker {
    height: 100%;
    width: 18px;
    border: none;
}

.sh_subgroup-container-left {
    width: -webkit-fit-content;
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    
    padding: 4px;
    padding-left: 8px;
    padding-right: 10px;
}

.sh_subgroup-container-left 
.___big-text {
    font-weight: bold;
    font-size: 14px;
    font-family: 'Uni';
    height: 22px;
}


.sh_subgroup-container-left 
.___little-text {
    font-size: 16px;
    height: 18px;
}

.sh_subgroup-container-right {
    width: 68px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.sh_subgroup-container-right >div{
    width: 50px;
    height: 50px;
    border: 3px solid red;
    background: linear-gradient( red, brown );
    border-radius: 7px;
    color: white;
    text-align: center;
    line-height: 18px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shadule-comp-left-container
.___header {
    width: 100%;
    height: 60px;
    background: rgb(189, 198, 199);
    padding: 14px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.shadule-comp-left-container
.___header
.____adres {
    font-size: 22px;
    font-family: 'PFD';
    font-weight: 100;
    line-height: 1px;
    color: black;
}

.shadule-comp-left-container
.___header
.____metro {
    font-size: 22px;
    font-family: 'MyriadPro';
    font-weight: 100;
    line-height: 1px;
    color: black;
}


.main_center_container_mobile
.___header {
    height: 56px;
    padding: 6px;
}


.main_center_container_mobile
.shadule-comp-left-container
.___header
.____adres {
    font-size: 14px;
    line-height: 14px;
}

.main_center_container_mobile
.shadule-comp-left-container
.___header
.____metro {
    font-size: 14px;
    line-height: 14px;
}

.shadule-comp-left-container
.__subgroups
{
    width: 100%;
}



.sh_subgroup_bycity_main {
    width: 100%;
    min-height: 500px;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 16px;

}

.main_center_container_mobile
.sh_subgroup_bycity_main { 
    max-width: 100vw;
    overflow-x: auto;
}

.sh_subgroup_bycity_citys {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.sh_subgroup_bycity_city {
    font-family: 'UNI';
    font-size: 25px;
    color: #053850;
}

.sh_subgroup_bycity_subgroups {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.sh_subgroup_bycity_subgroup {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid rgb(199, 199, 199);
    padding-left: 20px;

}

.sh_subgroup_bycity_subgroup_group {
    width: 150px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}
.sh_subgroup_bycity_subgroup_group_upper {
    width: 56px;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sh_subgroup_bycity_subgroup_group_down {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'PFD';
    font-size: 16px;
    color: #053850;
    line-height: 18px;

}

.sh_subgroup_bycity_subgroup_title {
    width: 450px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; 
}

.main_center_container_mobile
.sh_subgroup_bycity_subgroup_title {
    max-width: 160px;
}

.sh_subgroup_bycity_subgroup_title_upper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    grid-row-gap: 1px; 
    row-gap: 1px;
    line-height: 15px;
}

.sh_subgroup_bycity_subgroup_title_upper_left {
    width: -webkit-fit-content;
    width: fit-content;
    font-family: 'PFD';
    font-weight: bold;
    font-size: 17px;
    color: #053850;
}

.sh_subgroup_bycity_subgroup_title_upper_right {
    font-family: 'PFD';
    font-weight: bold;
    font-size: 15px;
    color: #053850;
    padding-top: 2px;
}

.sh_subgroup_bycity_subgroup_title_down {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start; 
    grid-column-gap: 15px; 
    -webkit-column-gap: 15px; 
            column-gap: 15px; 
}

.sh_subgroup_bycity_subgroup_title_down_metro {
    font-weight: bold;
}

.sh_subgroup_bycity_subgroup_shadule {
    width: 220px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.sh_subgroup_bycity_subgroup_shadule_one {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    grid-column-gap: 10px;

    -webkit-column-gap: 10px;

            column-gap: 10px;

    font-family: 'PFD';
    font-weight: bold;
    font-size: 12px;
    color: #053850;
}

.main_center_container_mobile
.sh_subgroup_bycity_subgroup_shadule_one {
    font-size: 8px;
}

.sh_subgroup_bycity_subgroup_payments {
    width: 170px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.sh_subgroup_bycity_subgroup_payment_one {
    width: 100%;
    font-family: 'PFD';
    font-weight: bold;
    font-size: 12px;
    color: #053850;
}

.sh_subgroup_bycity_subgroup_request {
    width: 100px;
    height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: red;
    color: white;
    font-family: 'MyriadPro';
    cursor: pointer;
}

.main_center_container_mobile
.sh_subgroup_bycity_subgroup_request {
    width: 50px;
    height: 30px;
    font-size: 9px;
    margin-left: 20px;
}

.sh_subgroup_bycity_subgroup_request span {
}
.group_visits_main_best {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.group_visits_main_best 
.raiting_big_plashka__best {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    flex-wrap: wrap;
    grid-row-gap: 10px;
    row-gap: 10px;
}

.main_center_container_mobile 
.raiting_big_plashka__best {
    width: 98vw;
    height: -webkit-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    flex-wrap: wrap;
    grid-row-gap: 10px;
    row-gap: 10px;

}

.group_visits_main_best_best {
    width: 100%;
}
.group_visits_main_best_table {    
    width: 90%;
}


.main_center_container_mobile 
.group_visits_main_best_table {
    width: 94%;
}

.group_visits_main_best
.raiting_big_plashka__oneraiting {
    background: white;
    color: black;
    font-family: 'Uni';
}

.group_visits_main_best
.raiting_big_plashka__best {
    background: none;
}

.group_visits_main h3 {
    font-size: 30px;
    font-family: 'DIN';
}

.group_visits_main_months {
    display: flex;
    flex-direction: column;
    grid-row-gap: 25px;
    row-gap: 25px;
    margin-top: 25px;
}

.group_visits_main > span {
    font-size: 16px;
    font-family: 'DIN';
    margin-right: 10px;
}
.person_data_change {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
    /* font-family: 'Uni'; */
    color: rgb(5, 56, 80);

    
    font-family: 'PFD';

}

.person_data_change_tabs .ant-tabs-tab {
    color: rgb(5, 56, 80);
    text-transform: uppercase;
}

.person_data_change_tabs .ant-tabs-tab-active {
    color: #1890ff;
}

.person_data_change_tabs .ant-tabs-left-content {
    color: black;
}
.purpose_component {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    background: none;
}

.purpose_search_conatiner {
    width: 130px;
    height: 22px;
    position: relative;
}

.purpose_search_conatiner__input {
    width: 100%;
    height: 100%;
}

.purpose_search_conatiner .ant-input {
    height: 20px !important;
}

.purpose_search_conatiner__results {
    width: 220px;
    min-height: 50px;
    position: absolute;

    padding: 5px;
    background: white;
    border-radius: 10px;

    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );

            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );

    top: 30px;
    left: -20px;

    z-index: 1000;

}

.purpose_search_conatiner__results_close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    left: calc(100% - 30px);

    font-size: 15px;
    opacity: 0.5;
    cursor: pointer;

    transition-duration: 0.5s;
    z-index: 3;

}

.purpose_search_conatiner__results_close:hover{
    opacity: 1;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.event_purpose_component {
    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: 200px;
}

.event_purpose_component_header {
    font-size: 18px;
    font-family: 'UNI';

    color: rgb(0, 57, 83);
}

.event_purpose_component_purposer {
    width: 300px;
}

.trefa_text_edit_main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    grid-row-gap: 20px;
    row-gap: 20px;
    padding: 5px;
}

.trefa_text_edit_main_container .trefa_text_container_textblock {
    text-transform: none;
}

.trefa_text_edit_main_container_fields {
    display: flex;
    flex-direction: row;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    padding: 10px;
    width: 100%;
}

.trefa_text_edit_left_container {
    min-width: 500px;
    max-width: 550px;

    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
}

.trefa_text_edit_input_component {
    display: flex;
    flex-direction: row;
    position: relative;
}
.trefa_text_edit_input_component_x {
    position: absolute;
    cursor: pointer;
    top: -10px;
    left: 100%;
    font-family: 'Uni';
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    border: 1px solid gray;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.trefa_text_edit_input_controls {
    width: 100px;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    background: rgb(241, 241, 241);
}

.trefa_text_edit_input_button {
    min-width: 30px;
    padding-left: 5px;
    padding-right: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid rgb(199, 199, 199);
    border-radius: 3px;

    cursor: pointer;
}

.trefa_text_edit_image_component {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 7px;
    row-gap: 7px;
    position: relative;

}

.trefa_text_edit_image_component_row {
    width: 100%;  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    grid-column-gap: 5px; 
    -webkit-column-gap: 5px; 
            column-gap: 5px;
}

.trefa_text_edit_added_component {
    width: 100%;
    height: 70px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 7px;
    border: 1px dotted gray;
    background: rgb(230, 230, 230);

    color: gray;

    margin-top: 5px;


}
.admin_modal {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
    /* font-family: 'Uni'; */
    color: rgb(5, 56, 80);

    
    font-family: 'PFD';

}

.admin_modal_tabs .ant-tabs-tab {
    color: rgb(5, 56, 80);
    text-transform: uppercase;
}

.admin_modal_tabs .ant-tabs-tab-active {
    color: #1890ff;
}

.admin_modal_tabs .ant-tabs-left-content {
    color: black;
}

.add_event_result {
    font-size: 18px;
    text-transform: none;
}

.payment_table_row {
    display: flex;
    flex-direction: row;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;

    font-size: 17px;
    height: 30px;
}

.payment_table_header {
    font-family: 'MyriadPro';
    font-size: 16px;
    height: 20px;
    margin-top: 10px;
    border-top: 1px solid rgb(216, 216, 216);
    color: rgb(5, 56, 80);
    font-weight: bold;
}

.payment_table_first_column {
    width: 150px;
    font-size: 19px;
}

.payment_table_second_column {
    width: 150px;
    font-size: 16px;
}

.payment_table_payment_column {
    width: 110px;
    text-align: center;
}

.main_center_container_mobile 
.seqpage_mainpage_main_plashka_inner
.ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
    padding-left: 3px !important;
    padding-right: 3px !important;
    font-size: 10px !important;
}

.main_center_container_mobile 
.subgroup_page_main_discription {
    padding-left: 10px;
    padding-right: 10px;
}
.group_page {
    height: -webkit-fit-content;
    height: fit-content;
    min-height: 600px;
    padding-bottom: 30px;
}
.global_footer {
    width: 100vw;
    height: -webkit-fit-content;
    height: fit-content;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    /* font-family: 'Uni'; */
    color: rgb(204, 204, 204) !important;

    background: rgb(0, 27, 36) !important;

    
    font-family: 'PFD';
    /* text-transform: uppercase; */
    /* font-weight: bold; */
    position: relative;

}

.global_footer_middle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;  

    width: 1200px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.main_center_container_mobile 
.global_footer_middle {
    width: 100vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;  
    padding-top: 10px;
    padding-bottom: 10px;

    padding-left: 10px;
    padding-right: 10px;


}

.global_footer_lefter {
    min-width: 350px;
}

.global_footer_righter {
    text-align: right;
    width: 100%;
}

.main_center_container_mobile
.global_footer_righter {
    margin-top: 30px;
    text-align: left;
}

.global_footer_p1 {
    font-size: 12px;
    height: 10px;
}

.global_footer_p2 {
    font-size: 14px;
    height: 11px;
}

.global_footer_p3 > span {
    margin-right: 22px;
    color: gray !important;
    font-size: 10px;
    /* white-space: nowrap; */
}

.global_footer_p3 > a {
    margin-right: 22px;
    color: gray !important;
    font-size: 10px;
    /* white-space: nowrap; */
}

.global_footer_p1_first {
    display: flex;
    flex-direction: row;
    grid-column-gap: 3px;
    -webkit-column-gap: 3px;
            column-gap: 3px;
}

.global_footer_p1_first .pumpcin_hw {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    opacity: 0.7;
}
::-webkit-scrollbar-button {
  background-image: url('');
  background-repeat: no-repeat;
  width: 4px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: #ecedee
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #ffbb00;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffd900;
}

::-webkit-resizer {
  background-image: url('');
  background-repeat: no-repeat;
  width: 4px;
  height: 7px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}

@font-face {
  font-family: 'MyriadProUsual';
  font-weight: 400;
  src: url(/static/media/MyriadPro-Regular.4dc5956a.otf);
}

@font-face {
  font-family: 'PFD';
  font-weight: 400;
  src: url("/static/media/PFDinTextCondPro Medium.bf203db2.ttf");
}

@font-face {
  font-family: 'MyriadPro';
  font-weight: 400;
  src: url(/static/media/MyriadPro-Cond.e2a5e608.otf);
}

@font-face {
  font-family: 'CH';
  font-weight: 400;
  src: url(/static/media/cricketheavy.8178d1e4.ttf);
}

@font-face {
  font-family: 'Bewlay';
  font-weight: 400;
  src: url(/static/media/Bewlay.8f431a7b.otf);
}

@font-face {
  font-family: 'Uni';
  font-weight: 400;
  src: url(/static/media/uni-sans.636c7246.otf);
}

@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  src: url(/static/media/rubik.e1437cc0.ttf);
}

@font-face {
  font-family: 'DIN';
  src: url(/static/media/dinpro_condensedblack.2e36241f.otf);
}

@font-face {
  font-family: 'SMS';
  src: url(/static/media/samsung.99c24bca.otf);
}

html {
  width: 100%;
  height: 100%;
}

body {
  background: black !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.main_center_container_mobile .ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  -webkit-transform: translate(50%, 0%) !important;
          transform: translate(50%, 0%) !important;
}
.global_bg_main {
  background: rgb(5, 56, 80);
}

.global_color_main {
  color: #053850;
}

a {
	color: rgb(0, 57, 83) !important;
}

a:hover {
	color: rgb(1, 112, 163) !important;
}

.global_marginLeft {
  margin-left: 20px;
}

.global-tooltip {
	cursor: help;
}

.global-tooltip::after {
	background: rgba(0, 0, 0, 0.8);
	border-radius: 8px 8px 8px 0px;
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
	color: #FFF;
	content: attr(data-tooltip); /* Главная часть кода, определяющая содержимое всплывающей подсказки */
	margin-top: -44px;
	opacity: 0; /* Наш элемент прозрачен... */
	padding: 13px 17px;
	position: absolute;
	visibility: hidden; /* ...и скрыт. */
			
	transition: all 0.4s ease-in-out; /* Добавить плавности по вкусу */
}
		
.global-tooltip:hover::after {
	opacity: 1; /* Показываем его */
	visibility: visible;
}


.global-slide-from-right-loading {
	opacity: 0;
	margin-left: 30px;
}

.global-slide-from-right {
		-webkit-animation-name: global-slide-from-right-animation;
		        animation-name: global-slide-from-right-animation;
		-webkit-animation-duration: 0.7s;
		        animation-duration: 0.7s;
		-webkit-animation-fill-mode: forwards;
		        animation-fill-mode: forwards;
		-webkit-animation-delay: 0.3s;
		        animation-delay: 0.3s;
		opacity: 0;


}

@-webkit-keyframes global-slide-from-right-animation {
	from {
		margin-left: 30px;
		/* transform: rotateY(-10deg); */
		opacity: 0;
	}
	to {
		margin-right: 0px;
		/* transform: rotateY(0deg);	 */
		opacity: 1;
	}
}

@keyframes global-slide-from-right-animation {
	from {
		margin-left: 30px;
		/* transform: rotateY(-10deg); */
		opacity: 0;
	}
	to {
		margin-right: 0px;
		/* transform: rotateY(0deg);	 */
		opacity: 1;
	}
}

.global_plashka_no_data {

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'PFD';
    color: gray;
    font-size: 17px;
    padding: 30px;

	text-align: center;
}

.global_discribe_text {
    font-family: 'PFD';
	color: rgb(1, 33, 48);
	width: 100%;
	padding: 20px 40px;
	font-size: 21px;
}

.main_center_container_mobile
.global_discribe_text {
	padding: 12px 16px;
	font-size: 12px;
}

.global_discribe_mobile_text {
	padding: 12px 16px;
	font-size: 12px;
}

.global_info_text {
	font-family: 'PFD';
	font-style: italic;
	color: rgb(105, 105, 105);
	width: -webkit-fit-content;
	width: fit-content;
	padding: 10px 40px;
	padding-bottom: 5px;
	font-size: 19px;
	-webkit-text-decoration-style: dotted;
	        text-decoration-style: dotted;
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
	text-decoration-skip-ink: auto;
	cursor: help;
}

.global_info_text_mini {
	padding: 5px 40px;
	padding-bottom: 2px;
	font-size: 16px;
}


.main_center_container_mobile 
.global_info_text {
	font-size: 15px;
}

.main_center_container_mobile 
.global_info_text_mini {
	font-size: 10px;
}

.global_info_text:hover {
	color: rgb(59, 59, 59);
}

.combo_scheme_plashka_inner {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
}

.solo_icon_checked {

	width: 20px;
	height: 20px;
  
	fill: yellow;
	stroke-width: 2;
	transition-duration: 2s;
	-webkit-filter: drop-shadow(1px 1px 1px rgb(255, 174, 0));
	        filter: drop-shadow(1px 1px 1px rgb(255, 174, 0)); 
  
	margin-right: 5px;
  
  }

.main-container {
    width: 100%;
    height: -webkit-fit-content;
    height: fit-content;
    min-height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    background: linear-gradient(rgb(0, 37, 48), black);

}

.main-backimage {

    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 20vw;

    background: unset;
    z-index: 0;

    
    background-size: cover !important;
    background-position: center !important;

}

.main-center-container {
    max-width: 1200px;
    width: 1200px;
    height: -webkit-fit-content;
    height: fit-content;
    min-height: 400px;

    background: rgb(245, 243, 243);
    /* background: white; */
    color: black;
    z-index: 1;
    /* z-index: 200; */
    margin-top: 30px;
}

.main_center_container_mobile {
    max-width: 100vw;
    width: 100vw;
    height: -webkit-fit-content;
    height: fit-content;
    min-height: 400px;

    background: rgb(245, 243, 243);
    /* background: white; */
    color: black;
    z-index: 1;
    margin-top: 30px; 

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    margin-top: 0px;
}

.ant-drawer-body {
    padding: 12px !important;
}

.main_center_container_mobile .trefa_plashka__ {
    align-items: flex-start !important;
}


