::-webkit-scrollbar-button {
  background-image: url('');
  background-repeat: no-repeat;
  width: 4px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: #ecedee
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-color: #ffbb00;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffd900;
}

::-webkit-resizer {
  background-image: url('');
  background-repeat: no-repeat;
  width: 4px;
  height: 7px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}

@font-face {
  font-family: 'MyriadProUsual';
  font-weight: 400;
  src: url('../fonts/MyriadPro-Regular.otf');
}

@font-face {
  font-family: 'PFD';
  font-weight: 400;
  src: url('../fonts/PFDinTextCondPro Medium.ttf');
}

@font-face {
  font-family: 'MyriadPro';
  font-weight: 400;
  src: url('../MyriadPro-Cond.otf');
}

@font-face {
  font-family: 'CH';
  font-weight: 400;
  src: url('../cricketheavy.ttf');
}

@font-face {
  font-family: 'Bewlay';
  font-weight: 400;
  src: url('../Bewlay.otf');
}

@font-face {
  font-family: 'Uni';
  font-weight: 400;
  src: url('../fonts/uni-sans.otf');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  src: url('../fonts/rubik.ttf');
}

@font-face {
  font-family: 'DIN';
  src: url('../fonts/dinpro_condensedblack.otf');
}

@font-face {
  font-family: 'SMS';
  src: url('../fonts/samsung.otf');
}

html {
  width: 100%;
  height: 100%;
}

body {
  background: black !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.main_center_container_mobile .ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  transform: translate(50%, 0%) !important;
}